import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CUSTOMER } from '../Axios/ENDPOINTS';

export interface AllClientsProps {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone_number: number,
  is_supplier: boolean,
  is_customer: boolean,
  balance: number
}

interface GetAllClientsOptions {
  is_customer?: boolean;
  is_supplier?: boolean;
}

export const useGetAllClients = (options: GetAllClientsOptions = {}) => {
  let url = `${END_POINT_CUSTOMER}/get_all`;

  const params = [];
  if (options.is_customer) params.push(`is_customer=true`);
  if (options.is_supplier) params.push(`is_supplier=true`);

  if (params.length > 0) url += `?${params.join("&")}`;

  const { data, error, mutate }: {
    data: AllClientsProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([url]);
  return {
    data,
    mutate,
    error
  };
};