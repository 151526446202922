import React, { useContext, useEffect,  useState } from 'react';
import './styles.scss'
import {  Button, Col, Form, Popconfirm, Row, Select, Table } from 'antd';
import {
    DeleteOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  {  TableProps } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { OperatorCashSectionProps } from './types';
import { UserValuesForm }  from '../../Forms/UserForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { useGetBranch } from '../../../Services/useGetBranch';
import { useGetCashDesk } from '../../../Services/useGetCashDesk';
import { OperationsCashProps, useGetOperationCash } from '../../../Services/useOperationCash';
import { useGetUsers } from '../../../Services/useGetUsers';


export interface DataType {
  index: string
  id: string,
  user_id: string,
  user_name: string,
  checkout_id: string,
  checkout_name: string,
  checkout_currency: string,
  branch_name: string,
  branch_id: string
}

type ColumnTypes = Exclude<TableProps<DataType>['columns'], undefined>;


interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof DataType;
  record: DataType;
  refresh: (loading: boolean) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  refresh,
  ...restProps
}) => {
  const { data: users } = useGetUsers();
  const [form] = useForm() 
  const { createOperationCash} = useGetOperationCash()
  const { Option } = Select;

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing) {
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
     
      const value = await form.getFieldsValue();
      const values ={
        user_id: value.user_name.key,
        checkout_id:  +record.checkout_id
      }
      const data = await createOperationCash(values)
      console.log(data)
      refresh(false);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (Array.isArray(children) && children[1] === 'select_operator') {
      childNode = (
        <Form
          form={form}
        >
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[{ required: true, message: `${title} is required.` }]}
          >
            <Select
              style={{ minWidth: 120 }}
              labelInValue
              onChange={save}
              placeholder="Seleccionar operador"
            >
              {users &&
                users.map((item) => (
                  <Option
                    value={`${item.first_name} ${item.last_name}`}
                    key={item.id}
                  >
                    {item.first_name} {item.last_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
       
      );
    } else {
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingInlineEnd: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};



const OperatorCashSection: React.FC<OperatorCashSectionProps> = () => {

    const {adjustedTableHeigthScroll} = useContext(StylesContext) 
    const { getOperationCash, deleteOperationCash} = useGetOperationCash()
    const {data: branches } = useGetBranch()
    const {data: cash} = useGetCashDesk() 
    const { Option} = Select

    const [form_currency] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [count, setCount] = useState(2);
    const [branchSelected, setBranchSelected] = useState<number | undefined>()
    const [cashSelected, setCashSelected] = useState<number | null>(null);

  const rowData = (dataResponse? : OperationsCashProps[]) =>{
      let filterData: DataType[]= []
      let values: UserValuesForm = form_currency.getFieldsValue() 
    
      if(dataResponse){
        dataResponse.map((data, index)=>{            
          filterData.push({
            index: index.toString(),
            id: data.id.toString(),
            user_id: data.user_id.toString(),
            user_name: data.user.first_name + ' ' + data.user.last_name,
            checkout_id: data.checkout_id.toString(),
            checkout_name: data.checkout.name,
            checkout_currency: data.checkout.currency.sign,
            branch_name: data.checkout.branch.name,
            branch_id: data.checkout.branch.id.toString()            
          })
        })                  
      }       
      setDataTable(filterData)
    }

    
    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Operador',
            dataIndex: 'user_name',
            key: '1',
            className:'cursor-pointer',
            sorter: (a, b) => a.user_name.localeCompare(b.user_name),
            editable: true,
        },
        {
          title: 'Sucursal',
          dataIndex: 'branch_name',
          key: '2',
          className:'cursor-pointer',          
          sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
          editable: true,
        },
        {
            title: 'Caja',
            dataIndex: 'checkout_name',
            key: '3',
            sorter: (a, b) => a.checkout_name.localeCompare(b.checkout_name),
            className:'cursor-pointer',
        },
        {
          title: 'Moneda',
          dataIndex: 'checkout_currency',          
          sorter: (a, b) => a.checkout_currency.localeCompare(b.checkout_currency),
          key: '4',
          className:'cursor-pointer',
        },        
        {
          title: 'operation',
          key:'5',
          dataIndex: 'Acción',
          render: (_, record) =>
            dataTable.length >= 1 ? (
              <Popconfirm 
                title="¿Seguro que desea eliminar la asignación?" 
                onConfirm={() => handleDelete(record)}
                okText="Eliminar" 
                cancelText="Cancelar"
              >
                <DeleteOutlined style={{marginLeft:'30px', color: '#37bde0'}}/>
              </Popconfirm>
            ) : null,


        },
    ];
    
    const handleDelete = async(record: DataType) => {
      if(record.id){
        const data = await deleteOperationCash(Number(record.id))
        if(data =='deleted'){
          const newData = dataTable.filter((item) => item.id !== record.id);
          setDataTable(newData);
        }        
      }else{
        const newData = dataTable.filter((item) => item.index !== record.index);
        setDataTable(newData);
      }
     
      
      
    
    };

    const handleAdd = () => {
      if(cashSelected && cash){
        let data = cash.find((item)=>item.id == cashSelected)
        const newData: DataType = {
          index: count.toString(),
          id: '',
          user_id: '',
          user_name: 'select_operator',
          checkout_id: data?.id.toString() || '',
          checkout_name: data?.name || '',
          checkout_currency: data?.currency.sign || '',
          branch_name: data?.branch.name || '',
          branch_id: data?.branch.id.toString() || '',
        };
        setDataTable([...dataTable, newData]);
        setCount(count + 1);
      }
     
    };

    const refresh = async(loading: boolean, e?: number) =>{      
      let cash_id = e ? e : cashSelected
      if(cash_id){
        setLoadingTableData(loading)
        const banches = await getOperationCash(cash_id)
        if(banches){
          rowData(banches) 
          setLoadingTableData(false)
        }  
      }
        
    }
    
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = defaultColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: DataType) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          refresh,
        }),
      };
    });

    const changeCash = async(e: {key: string}) =>{
      setCashSelected(+e.key)
      refresh(true, +e.key)
    }

    return( 
        <>
          <div className='currency_section currency_section_container'>                                  
              <Row
                 className='global_title_section'  
              >
                  <h3>OPERADORES DE CAJA</h3>                
              </Row>
              <Row 
                className='global_button_box'
                style={{
                  display:'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Row
                  style={{ gap: '10px' }}
                >
                  <Col
                  >
                    <Select
                      style={{minWidth: 120}}
                      labelInValue  
                      onChange={(e)=>{
                        setBranchSelected(+e.key)
                        setCashSelected(null);
                      }}
                      placeholder= 'Sucursal'

                    >
                        {branches && branches.map((item) => (
                          item.id !== 0 &&
                            <Option value={item.name} key={item.id}>
                                {item.name}
                            </Option>
                        ))} 
                    </Select>                 
                  </Col>
                  <Col>
                      <Select
                        labelInValue
                        style={{minWidth: 120}}
                        placeholder= 'Caja'
                        onChange={changeCash}
                        value={cashSelected ? { 
                          key: cashSelected.toString(), 
                          label: cash.find((item)=>item.id == cashSelected)?.name, 
                          value: cash.find((item)=>item.id == cashSelected)?.name 
                        } : null}  // Mostrar el valor seleccionado
                      >
                        {cash && cash
                          .filter((item) => item.branch.id === branchSelected || item.branch.id === 0)
                          .map((item) => (
                            <Option value={item.name} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </Col>                  
                </Row>
                  <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16, marginRight: '30px' }}>
                    Agregar operador
                  </Button>                                              
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '80%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  :  <Table<DataType>
                      components={components}
                      rowClassName={() => 'editable-row'}
                      dataSource={dataTable}
                      columns={columns as ColumnTypes}
                      scroll={{ y: adjustedTableHeigthScroll }}  
                      style={{
                                width: '100%',
                                marginTop: '2%'
                            }}
                    />  
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default OperatorCashSection;