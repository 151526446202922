import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import {  Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { PucrchaseSaleCurrencyFormProps } from "./types";
import {  useGetAllClients } from "../../../Services/useGetAllClients";
import { FormItemsBuy } from "./FormItemsBuy";
import { FormItemsSell } from "./FormItemsSell";
import { ResponseAccounts } from "../../../Services/useAccounts";
import './styles.scss';
import { useOperations } from "../../../Services/useOperations";
import { useCurrency } from "../../../Services/useCurrency";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { MovementAccountContext } from "../../../Contexts/UserContext/MovementAcountContext";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface ValuesForm {
    operation_type: FilterValues,   
    sell_currency: FilterValues,
    extraction_cash: FilterValues,
    buy_currency: FilterValues,
    deposit_cash: FilterValues,
    type_change: string,
    amount: string,
    description: string,
    approved: boolean,
    full_name: string
}


export const Windows: React.FC<PucrchaseSaleCurrencyFormProps> =({
    form_buy_and_sell,
    rowSelect,
    setRowSelect
})=>{
    const {data: clients} = useGetAllClients()    
    const {createOperation, editOperation, getOperation} = useOperations()
    const {getExchangeRate} = useCurrency()
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms } = useContext(VariablesContext) 
    const { userData } =useContext(UserContext)
    const [ spanError, setSpanError ] = useState<string | undefined>()      
    const { Option } = Select;

    const {
        setMutate,
        showingAccount, 
      } = useContext(MovementAccountContext)

    const {
        setMutate: mutateOperationsSections,     
    } = useContext(OperationsContext)
     
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [operationType, setOperationType] = useState<'sell' | 'buy' | undefined>()
    const [aproved, setAproved] = useState(false)    
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()
    const [count, setCount] = useState(0)
    const [result, setResult] = useState<number>()
    const [resultCurrency, setResulCurrency] = useState<string>()
    const [amount, setAmount] = useState<any>()
    const [typeChange, setTypeChange] = useState<any>()
    const [customerSelected, setCustomerSelected] = useState<{
        name: string,
        id: string,
    }>()
    const [selectedSellingCurrency, setSelectedSellingCurrency] = useState<{
        id: string,
        sign: string,
        name: string
    }>()

    const [selectedPurchaseCurrency, setSelectedPurchaseCurrency] = useState<{
        id: string,
        sign: string,
        name: string
    }>()

    useEffect(()=>{
        if(typeChange && amount){
            setResult(typeChange*amount)
        }

    },[amount, typeChange])

    const updateForm = async(rowSelect: any) =>{
        const response = await getOperation(+rowSelect.id) 
        console.log(response)
        if(response){

        let operationType: 'sell' | 'buy'  = response.currency_sale ? 'sell' : 'buy';
        let type_change = ''
        let operationAmount = operationType == 'sell' ? response.credit  : response.debit
        setOperationType(operationType);          
        //Si la financiera compra: Hay extracción de origin_account, puede haber depósito en checkout_destination  
        if(response.origin_account?.currency?.id){
            setSelectedPurchaseCurrency({
                id: response.origin_account.currency.id.toString(),
                sign: response.origin_account.currency.sign, 
                name: response.origin_account.currency.name
            })
        }     
        //Si la financiera vende: Hay depósito en origin_account, puede haber extracción en checkout_destination   
        if(response.destination_account?.currency?.id){
            setSelectedSellingCurrency({
                id: response.destination_account.currency.id.toString(), 
                sign: response.destination_account.currency.sign, 
                name: response.destination_account.currency.name 
            })
        }     
        if(operationType == 'sell'){
            setResulCurrency(response.origin_account.currency.sign) 
        }else{
            setResulCurrency(response.destination_account.currency.sign)  
        }   
        if(response.currency_quote_buy_price){
            type_change = response.currency_quote_buy_price.toString() 
        }
        if(response.currency_quote_sell_price){
            type_change = response.currency_quote_sell_price.toString()
        }

        form_buy_and_sell.setFieldsValue(
            { 
                operation_type: {
                    value: operationType, 
                    label: response.currency_sale ? 'Venta' : 'Compra'
                },
                full_name: response.full_name, 
                deposit_cash:{
                    value: response.destination_checkout.name || undefined, 
                    label: response.destination_checkout.name || undefined, 
                    key: response.destination_checkout.id || undefined 
                },
                extraction_cash:{
                    value: response.origin_checkout.name || undefined, 
                    label: response.origin_checkout.name || undefined, 
                    key: response.origin_checkout.id || undefined
                },
                branch_cahs:{
                    value: response.destination_checkout.branch.id || undefined,
                    label: response.destination_checkout.branch.name || undefined, 
                    key: response.destination_checkout.branch.name || undefined 
                },
                branch_cahs_extraction:{
                    value: response.origin_checkout.branch.id || undefined, 
                    label: response.origin_checkout.branch.name || undefined,
                    key: rowSelect.origin_checkout_branch.name || undefined,
                },
                type_change: type_change,
                amount: operationAmount,
                description:response.description || undefined, 
            }
        )
        if(type_change){
            setTypeChange(type_change)
        }
        if(operationAmount){
            setAmount(operationAmount)
        }
        setCount(1)
        }
        
    }

    useEffect(()=>{               
        if(rowSelect){
            updateForm(rowSelect)
        }else{            
            form_buy_and_sell.resetFields()
            setSelectedSellingCurrency(undefined)
            setSelectedPurchaseCurrency(undefined)
        }
    },[])

    useEffect(()=>{
        if(selectedPurchaseCurrency && selectedSellingCurrency){
            if(rowSelect){
                setCount(2)
                if(count > 1){
                    checkExchangeRate()                 
                }
            }else{
                checkExchangeRate()               
            } 
        } 
             
    },[selectedPurchaseCurrency, selectedSellingCurrency])

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        setLoadingBtn(false)
        form_buy_and_sell.resetFields()
        if(setRowSelect){
            setRowSelect(undefined)
        }
    }

    const checkExchangeRate = async()=>{
        let main_currency_id 
        let secondary_currency_id
        
        if(selectedSellingCurrency && selectedPurchaseCurrency){
            switch(operationType){
                case 'buy':
                    main_currency_id = selectedPurchaseCurrency.id
                    secondary_currency_id = selectedSellingCurrency.id
                    setResulCurrency(selectedSellingCurrency.sign)
                    break
                case 'sell':
                    main_currency_id = selectedSellingCurrency.id
                    secondary_currency_id = selectedPurchaseCurrency.id
                    setResulCurrency(selectedPurchaseCurrency.sign)
                    break;
                default:
                    main_currency_id = undefined
                    secondary_currency_id = undefined
                    break;
            }
            if(main_currency_id && secondary_currency_id){
                const data = await getExchangeRate( +main_currency_id, +secondary_currency_id)
                if (data){
                    switch(operationType){
                        case 'buy':
                            form_buy_and_sell.setFieldValue('type_change', data.buy_price)
                            setTypeChange(data.buy_price)
                            break;
                        case 'sell':
                            form_buy_and_sell.setFieldValue('type_change', data.sell_price)
                            setTypeChange(data.sell_price)
                            break;
                        default:
                            setSpanError('No hay tipo de cambio, cree uno o ingreselo manual')
                            break;
                    }                    
                }
            }
        }        
    }

    const clearState = () =>{
        if(setRowSelect){
            setRowSelect(undefined)
        }
        setSelectedSellingCurrency(undefined)
        setSelectedPurchaseCurrency(undefined)
        setBalance(undefined)
        form_buy_and_sell.resetFields([
            'client', 
            'buy_currency', 
            'deposit_cash', 
            'sell_currency', 
            'extraction_cash', 
            'type_change', 
            'aproved', 
            'amount', 
            'description'
        ]);
    }
      
    const handleSave = async (values: ValuesForm)=>{
        setLoadingBtn(true)
        if(selectedPurchaseCurrency && selectedSellingCurrency && userData){
            let newData
            let operationData={
                description: values.description || '',
                type_id: 7, //id de compra venta moneda              
                main_currency_id: operationType == 'buy' ? +selectedPurchaseCurrency.id : +selectedSellingCurrency.id,
                secondary_currency_id:  operationType == 'buy' ?  +selectedSellingCurrency.id  : +selectedPurchaseCurrency.id, 
                user_id: userData.id,
                debit: Number(values.amount),
                currency_quote_sell_price: operationType =='sell' ? Number(values.type_change) : undefined,
                currency_sale: operationType =='sell' ? true : false,
                approved: aproved,
                currency_quote_buy_price: operationType =='buy' ? Number(values.type_change) : undefined ,//No está más?                      
                origin_checkout_id: values.extraction_cash ? Number(values.extraction_cash.key) : undefined, //Hubo extracción en cuenta  
                destination_checkout_id: values.deposit_cash?.key ?  Number(values.deposit_cash.key) : undefined, //Hubo depósito en cuenta  
                full_name:   values.full_name,
                windows_operation: true                       
            }
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id!, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }      
            setCollapsedFilters(!collapsedFilsters)  
        }                       
    }  
    
    const handleOperationType = (event:{value:'sell' | 'buy' } ) =>{
        if(event){
            setOperationType(event.value)
        }
        clearState()  
        setAproved(false)     
    }   

    const handleChangeClients = (values: {
        label: string,
        value: string,
        key: string
    }) =>{
        setCustomerSelected({
            id: values.key,
            name: values.label
        })
    }

    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };
    
    const amountSign = () => {
        let sign = '$'        
        switch(operationType){
            case 'buy':
                if(selectedPurchaseCurrency){
                    sign = selectedPurchaseCurrency.sign
                }
                break
            case 'sell':
                if(selectedSellingCurrency){
                    sign = selectedSellingCurrency.sign
                }
                break;
            default:
                sign= '$'
                break;
        }
        return sign
    };

    const amountExangeRate = () => {
        let sign = '$'               
        switch(operationType){
            case 'buy':
                if(selectedSellingCurrency){
                    sign = selectedSellingCurrency.sign
                }
                break
            case 'sell':
                if(selectedPurchaseCurrency){
                    sign = selectedPurchaseCurrency.sign
                }
                break;
            default:
                sign= '$'
                break;
        }
        return sign
    }; 

 
    return(
        <div>            
            <Modal
                title= 'Compra / Venta: Ventanilla'
                open={showForms.windows }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_buy_and_sell.submit()}
            >
                <Form
                    form={form_buy_and_sell}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >      
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            placeholder='Seleccione el tipo de operación'   
                            disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}
                            onChange={(event)=> handleOperationType(event)}          
                            options={[
                                { value: 'sell', label: 'Venta' },
                                { value: 'buy', label: 'Compra' },
                            ]}                   
                        />
                    </Form.Item> 
                    <Form.Item
                        label='Nombre y apellido'
                        name='full_name'
                        rules={mandatory} 
                    >
                        <Input
                            placeholder="Nombre y apellido"
                        />             
                    </Form.Item> 
                    {
                        operationType && 
                        <>
                            {  
                               operationType == 'buy'
                                ? 
                                    <>
                                        <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSelectedPurchaseCurrency={setSelectedPurchaseCurrency}  
                                            selectedPurchaseCurrency={selectedPurchaseCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance} 
                                            rowSelect={rowSelect}                               
                                        />
                                        <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSelectedSellingCurrency={setSelectedSellingCurrency}  
                                            selectedSellingCurrency={selectedSellingCurrency}   
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                    </>                                    
                                :      
                                    <>              
                                         <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSelectedSellingCurrency={setSelectedSellingCurrency}  
                                            selectedSellingCurrency={selectedSellingCurrency}   
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                       <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSelectedPurchaseCurrency={setSelectedPurchaseCurrency}  
                                            selectedPurchaseCurrency={selectedPurchaseCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance}      
                                            rowSelect={rowSelect}                               
                                        />
                                    </>
                            }                                                                                    
                            <Form.Item
                                label= 'Tipo de cambio'         
                                name= 'type_change'
                                className="sell_and_buy_divide"  
                            >
                                <InputNumber
                                    addonBefore={amountExangeRate()}
                                    onChange={(e)=>setTypeChange(e)}
                                />
                            </Form.Item>
                            <Row
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    gap: 4
                                }}
                            >
                                <Col style={{ flex: 1 }}>
                                    <Form.Item
                                       label= {operationType == 'buy' ? 'Monto de compra' : 'Monto de venta'}         
                                        name= 'amount'
                                    >
                                        <InputNumber
                                            addonBefore={amountSign()}
                                            onChange={(e)=>{setAmount(e)}}
                                        />                    
                                    </Form.Item>
                                </Col>
                                <Col style={{ flex: 1 }}>
                                    <Form.Item
                                        label= 'Resultado'         
                                        name= 'result'
                                        style={{marginLeft: 20}}
                                    >
                                        <span style={{marginRight: 10, fontWeight: 700}}>{resultCurrency}</span>
                                        <span style={{fontSize: 15, fontWeight: 700}}>
                                            {result && Number(result).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </span>
                                        
                                        {/* <InputNumber
                                            addonBefore={amountSign()}
                                            disabled={true}
                                            value= '100'
                                        />                     */}
                                    </Form.Item>
                                </Col>
                            </Row>                         
                        </>                                          
                    }  
                     <Form.Item
                        name='aproved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={aproved}
                            onChange={onChangeAproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                     <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>                                  
                </Form>
                <span style={{color: 'red'}}>{spanError && spanError}</span>
            </Modal>
        </div>
    )
}

export default Windows;