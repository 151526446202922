import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import {  Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { PucrchaseSaleCurrencyFormProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { FormItemsBuy } from "./FormItemsBuy";
import { FormItemsSell } from "./FormItemsSell";
import { ResponseAccounts } from "../../../Services/useAccounts";
import './styles.scss';
import { useOperations } from "../../../Services/useOperations";
import { useCurrency } from "../../../Services/useCurrency";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface ValuesForm {
    operation_type: FilterValues,
    client: FilterValues,   
    sell_currency: FilterValues,
    extraction_cash: FilterValues,
    buy_currency: FilterValues,
    deposit_cash: FilterValues,
    type_change: string,
    amount: string,
    description: string,
    approved: boolean
}


export const PucrchaseSaleCurrencyForm: React.FC<PucrchaseSaleCurrencyFormProps> =({
    form_buy_and_sell,
    rowSelect,
    setRowSelect, 
})=>{

    const {data: clients} = useGetAllClients()    
    const {createOperation, editOperation, getOperation} = useOperations()
    const {getExchangeRate} = useCurrency()
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms} = useContext(VariablesContext) 
    const { userData } =useContext(UserContext)
    const [ spanError, setSpanError ] = useState<string | undefined>()      
    const { Option } = Select;   
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [operationType, setOperationType] = useState<'sell' | 'buy' | undefined>()
    const [aproved, setAproved] = useState(false)    
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()
    const [count, setCount] = useState(0)
    const [result, setResult] = useState<number>()
    const [resultCurrency, setResulCurrency] = useState<string>()
    const [amount, setAmount] = useState<number>()
    const [typeChange, setTypeChange] = useState<number>()
    const [customerSelected, setCustomerSelected] = useState<{
        name: string,
        id: string,
    }>()
    const [selectedSellingCurrency, setSelectedSellingCurrency] = useState<{
        id: string,
        sign: string,
        name: string
    }>()

    const [selectedPurchaseCurrency, setSelectedPurchaseCurrency] = useState<{
        id: string,
        sign: string,
        name: string
    }>()

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]  

    useEffect(()=>{ 
        if(rowSelect){
            uploadData()
        }else{            
            form_buy_and_sell.resetFields()
            setSelectedSellingCurrency(undefined)
            setSelectedPurchaseCurrency(undefined)
        }    
    },[])

    useEffect(()=>{
        if(selectedPurchaseCurrency && selectedSellingCurrency){
            if(rowSelect){
                setCount(2)
                if(count > 1){
                    checkExchangeRate()                 
                }
            }else{
                checkExchangeRate()               
            } 
        } 
             
    },[selectedPurchaseCurrency, selectedSellingCurrency])

    useEffect(()=>{
        if(typeChange && amount){
            setResult(typeChange*amount)
        }

    },[amount, typeChange])


    const getAmount = async(id: number)=>{
        const response = await getOperation(id)
        if(response){
            return response.currency_sale 
                        ? response.credit.toString()
                        : !response.currency_sale
                            ? response.debit.toString()
                            : ''                                                        
        }else{
            return ''
        }
    }

    const uploadData = async() =>{
        let operationAmount : string = ''
        let type_change: string = ''

        if(rowSelect && rowSelect.id){
            const response = await getOperation(+rowSelect.id)
            if(response){
                setOperationType(response.currency_sale ? 'sell' : 'buy') 
                if(response.origin_account.currency.id){
                    setSelectedPurchaseCurrency({
                        id: response.origin_account.currency?.id?.toString() || '',
                        sign: response.origin_account?.currency?.sign || '',
                        name: response.origin_account?.currency?.name || ''
                    })
                }   
                if(response.destination_account.currency.id){
                    setSelectedSellingCurrency({
                        id: response.destination_account.currency.id.toString(),
                        sign: response.destination_account.currency?.sign || '',
                        name: response.destination_account.currency?.name || ''
                    })
                } 
                if(response.currency_sale){
                    setResulCurrency(response.origin_account?.currency?.sign || '')    
                }else{
                    setResulCurrency(response.destination_account?.currency?.sign || '') 
                } 

                if(response.currency_quote_buy_price){
                    type_change = response.currency_quote_buy_price.toString()
                }
                if(response.currency_quote_sell_price){
                    type_change = response.currency_quote_sell_price.toString()
                }

                operationAmount = response.currency_sale 
                ? response.credit.toString()
                : !response.currency_sale
                    ? response.debit.toString()
                    : ''   
                    
                form_buy_and_sell.setFieldsValue(
                    { 
                        operation_type: {
                            value: operationType, 
                            label:  rowSelect.currency_sale === 'true' ? 'Venta' : 'Compra',
                        },
                        client: {
                            value: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
                            label: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
                            key: rowSelect.customer_id,
                        },
                        deposit_cash:{
                            value: response.destination_checkout?.name || undefined,
                            label: response.destination_checkout?.name || undefined,
                            key: response.destination_checkout?.id|| undefined,
                        },
                        extraction_cash:{
                            value: response.origin_checkout?.name || '',
                            label: response.origin_checkout?.name|| '',
                            key: response.origin_checkout?.id || '',
                        },
                        branch_cahs:{
                            value: response.destination_checkout?.branch?.id || undefined,
                            label: response.destination_checkout?.branch?.name || undefined,
                            key: response.destination_checkout?.branch?.name || undefined,
                        },
                        branch_cahs_extraction:{
                            value: response.origin_checkout?.branch?.id || undefined,
                            label: response.origin_checkout?.branch?.name || undefined,
                            key: response.origin_checkout?.branch?.name || undefined,
                        },
                        comission: response.usdt_quote_percentage || '',
                        type_change: type_change,
                        amount: operationAmount,
                        description: response.description
                    }
                )   
                
                if(type_change){
                    setTypeChange(Number(type_change))
                }
                if(operationAmount){
                    setAmount(Number(operationAmount))
                }
            }            
            setCount(1)
        }              
    }


    // const uploadData = async()=>{
    //     if(rowSelect){
    //         let operationType: 'sell' | 'buy'  = rowSelect.currency_sale === 'true' ? 'sell' : 'buy';
    //         let type_change = ''
    //         let operationAmount: string =  
    //             rowSelect.is_sale == 'true' && rowSelect.credit_number  
    //                 ? rowSelect.credit_number  
    //                 : rowSelect.is_sale == 'false' && rowSelect.debit_number
    //                     ? rowSelect.debit_number : ''
            
    //         setOperationType(operationType);          
    //         //Si la financiera compra: Hay extracción de origin_account, puede haber depósito en checkout_destination  
    //         if(rowSelect.origin_account_currency_id){
    //             setSelectedPurchaseCurrency({
    //                 id: rowSelect.origin_account_currency_id,
    //                 sign: rowSelect.origin_account_currency_sign!,
    //                 name: rowSelect.origin_account_currency_name!
    //             })
    //         }     
    //         //Si la financiera vende: Hay depósito en origin_account, puede haber extracción en checkout_destination   
    //         if(rowSelect.destination_account_currency_id){
    //             setSelectedSellingCurrency({
    //                 id: rowSelect.destination_account_currency_id,
    //                 sign: rowSelect.destination_account_currency_sign!,
    //                 name: rowSelect.destination_account_currency_name!
    //             })
    //         }   
    //         if(operationType == 'sell'){
    //             setResulCurrency(rowSelect.origin_account_currency_sign || '')    
    //         }else{
    //             setResulCurrency(rowSelect.destination_account_currency_sign || '') 
    //         }   
             
    //         if(rowSelect.currency_quote_buy_price){
    //             type_change = rowSelect.currency_quote_buy_price
    //         }
    //         if(rowSelect.currency_quote_sell_price){
    //             type_change = rowSelect.currency_quote_sell_price
    //         }
      
    //         form_buy_and_sell.setFieldsValue(
    //             { 
    //                 operation_type: {
    //                     value: operationType, 
    //                     label:  rowSelect.currency_sale === 'true' ? 'Venta' : 'Compra',
    //                 },
    //                 client: {
    //                     value: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
    //                     label: rowSelect.customer_first_name + ' ' + rowSelect.customer_last_name,
    //                     key: rowSelect.customer_id,
    //                 },
    //                 deposit_cash:{
    //                     value: rowSelect.destination_checkout_name || undefined,
    //                     label: rowSelect.destination_checkout_name || undefined,
    //                     key: rowSelect.destination_checkout_id || undefined,
    //                 },
    //                 extraction_cash:{
    //                     value: rowSelect.origin_checkout_name || '',
    //                     label: rowSelect.origin_checkout_name || '',
    //                     key: rowSelect.origin_checkout_id || '',
    //                 },
    //                 branch_cahs:{
    //                     value: rowSelect.destination_checkout_branch_id || undefined,
    //                     label: rowSelect.destination_checkout_branch || undefined,
    //                     key: rowSelect.destination_checkout_branch || undefined,
    //                 },
    //                 branch_cahs_extraction:{
    //                     value: rowSelect.origin_checkout_branch_id || undefined,
    //                     label: rowSelect.origin_checkout_branch || undefined,
    //                     key: rowSelect.origin_checkout_branch || undefined,
    //                 },
    //                 type_change: type_change,
    //                 amount: operationAmount,
    //                 description: rowSelect.description
    //             }
    //         )
    //         if(type_change){
    //             setTypeChange(Number(type_change))
    //         }
    //         if(operationAmount){
    //             setAmount(Number(operationAmount))
    //         }
    //         setCount(1)

    //     }
    // }


    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        setLoadingBtn(false)
        form_buy_and_sell.resetFields()
        if(setRowSelect){
            setRowSelect(undefined)
        }
    }

    // const getBalance = async() =>{   
    //     const data = await getAccountsCurrencyAndCustomer(Number(client), Number(currency?.id))
    //     if(data){
    //         setBalance(data)
    //     }        
    // }

    const checkExchangeRate = async()=>{
        let main_currency_id 
        let secondary_currency_id
        
        if(selectedSellingCurrency && selectedPurchaseCurrency){
            switch(operationType){
                case 'buy':
                    main_currency_id = selectedPurchaseCurrency.id
                    secondary_currency_id = selectedSellingCurrency.id
                    setResulCurrency(selectedSellingCurrency.sign)
                    break
                case 'sell':
                    main_currency_id = selectedSellingCurrency.id
                    secondary_currency_id = selectedPurchaseCurrency.id
                    setResulCurrency(selectedPurchaseCurrency.sign)
                    break;
                default:
                    main_currency_id = undefined
                    secondary_currency_id = undefined
                    break;
            }
            if(main_currency_id && secondary_currency_id){
                const data = await getExchangeRate( +main_currency_id, +secondary_currency_id)
                if (data){
                    switch(operationType){
                        case 'buy':
                            form_buy_and_sell.setFieldValue('type_change', data.buy_price)
                            setTypeChange(data.buy_price)
                            break;
                        case 'sell':
                            form_buy_and_sell.setFieldValue('type_change', data.sell_price)
                            setTypeChange(data.sell_price)
                            break;
                        default:
                            setSpanError('No hay tipo de cambio, cree uno o ingreselo manual')
                            break;
                    }                    
                }
            }
        }        
    }

    const clearState = () =>{
        if(setRowSelect){
            setRowSelect(undefined)
        }
        setSelectedSellingCurrency(undefined)
        setSelectedPurchaseCurrency(undefined)
        setBalance(undefined)
        form_buy_and_sell.resetFields([
            'client', 
            'buy_currency', 
            'deposit_cash', 
            'sell_currency', 
            'extraction_cash', 
            'type_change', 
            'aproved', 
            'amount', 
            'description'
        ]);
    }
      
    const handleSave = async (values: ValuesForm)=>{
        setLoadingBtn(true)
        if(selectedPurchaseCurrency && selectedSellingCurrency && userData){
            let newData
            let operationData={
                description: values.description || '',
                type_id: 7, //id de compra venta moneda              
                main_currency_id: operationType == 'buy' ? +selectedPurchaseCurrency.id : +selectedSellingCurrency.id,
                secondary_currency_id:  operationType == 'buy' ?  +selectedSellingCurrency.id  : +selectedPurchaseCurrency.id, 
                user_id: userData.id,
                debit: Number(values.amount),
                currency_quote_sell_price: operationType =='sell' ? Number(values.type_change) : undefined,
                currency_sale: operationType =='sell' ? true : false,
                approved: aproved,
                customer_id: Number(values.client.key),
                currency_quote_buy_price: operationType =='buy' ? Number(values.type_change) : undefined ,//No está más?                      
                origin_checkout_id: values.extraction_cash ? Number(values.extraction_cash.key) : undefined, //Hubo extracción en cuenta  
                destination_checkout_id: values.deposit_cash?.key ?  Number(values.deposit_cash.key) : undefined, //Hubo depósito en cuenta                           
            }
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id!, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }
            // if(newData){
            //     if (showingAccount && showingAccount.user_id == newData.customer.id &&
            //         (
            //             showingAccount.sign_id == newData.origin_account?.currency.id || 
            //             showingAccount.sign_id == newData.destination_account?.currency.id
            //         ))
            //     {
            //         setMutate(true)
            //     }
            //     mutateOperationsSections(true)
            // }         
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)  
        }                       
    }  
    
    const handleOperationType = (event:{value:'sell' | 'buy' } ) =>{
        if(event){
            setOperationType(event.value)
        }
        clearState()  
        setAproved(false)     
    }   

    const handleChangeClients = (values: {
        label: string,
        value: string,
        key: string
    }) =>{
        setCustomerSelected({
            id: values.key,
            name: values.label
        })
    }

    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };
    
    const amountSign = () => {
        let sign = '$'        
        switch(operationType){
            case 'buy':
                if(selectedPurchaseCurrency){
                    sign = selectedPurchaseCurrency.sign
                }
                break
            case 'sell':
                if(selectedSellingCurrency){
                    sign = selectedSellingCurrency.sign
                }
                break;
            default:
                sign= '$'
                break;
        }
        return sign
    };

    const amountExangeRate = () => {
        let sign = '$'               
        switch(operationType){
            case 'buy':
                if(selectedSellingCurrency){
                    sign = selectedSellingCurrency.sign
                }
                break
            case 'sell':
                if(selectedPurchaseCurrency){
                    sign = selectedPurchaseCurrency.sign
                }
                break;
            default:
                sign= '$'
                break;
        }
        return sign
    }; 
  
 
    return(
        <div>            
            <Modal
                title= 'Compra / Venta: Cliente'
                open={showForms.buy_and_sell }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_buy_and_sell.submit()}
            >
                <Form
                    form={form_buy_and_sell}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >      
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            placeholder='Seleccione el tipo de operación'   
                            disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}
                            onChange={(event)=> handleOperationType(event)}          
                            options={[
                                { value: 'sell', label: 'Venta' },
                                { value: 'buy', label: 'Compra' },
                            ]}                   
                        />
                    </Form.Item> 
                    <Form.Item
                        label='Cliente'
                        name='client'
                        rules={mandatory} 
                    >
                        <Select                           
                            labelInValue  
                            loading={!clients }
                            placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                            style={{minWidth: '200px'}}  
                            onChange={handleChangeClients}       
                            disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}             
                        >
                            {clients && clients.map((item) => (
                                <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                    {item.first_name + ' ' + item.last_name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    {
                        operationType && 
                        <>
                            {  
                               operationType == 'buy'
                                ? 
                                    <>
                                        <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSelectedPurchaseCurrency={setSelectedPurchaseCurrency}  
                                            selectedPurchaseCurrency={selectedPurchaseCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance} 
                                            rowSelect={rowSelect}                                   
                                        />
                                        <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSelectedSellingCurrency={setSelectedSellingCurrency}  
                                            selectedSellingCurrency={selectedSellingCurrency}   
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                    </>                                    
                                :      
                                    <>              
                                         <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSelectedSellingCurrency={setSelectedSellingCurrency}  
                                            selectedSellingCurrency={selectedSellingCurrency}   
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                       <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSelectedPurchaseCurrency={setSelectedPurchaseCurrency}  
                                            selectedPurchaseCurrency={selectedPurchaseCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance}      
                                            rowSelect={rowSelect}                               
                                        />
                                    </>
                            }
                            
                            <Form.Item
                                label= 'Tipo de cambio'         
                                name= 'type_change'
                                className="sell_and_buy_divide"  
                                rules={mandatory}
                            >
                                <InputNumber
                                    addonBefore={amountExangeRate()}
                                    onChange={(e)=>setTypeChange(Number(e))}
                                />
                            </Form.Item>
                            <Row
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    gap: 4
                                }}
                            >
                                <Col style={{ flex: 1 }}>
                                    <Form.Item
                                        label= {operationType == 'buy' ? 'Monto de compra' : 'Monto de venta'}         
                                        name= 'amount'
                                        rules={mandatory}
                                    >
                                        <InputNumber
                                            addonBefore={amountSign()}
                                            onChange={(e)=>{setAmount(Number(e))}}
                                        />                    
                                    </Form.Item>
                                </Col>
                                <Col style={{ flex: 1 }}>
                                    <Form.Item
                                        label= 'Resultado'         
                                        name= 'result'
                                        style={{marginLeft: 20}}
                                    >
                                        <span style={{marginRight: 10, fontWeight: 700}}>{resultCurrency}</span>
                                        <span style={{fontSize: 15, fontWeight: 700}}>
                                            {result && Number(result).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </span>
                                        
                                        {/* <InputNumber
                                            addonBefore={amountSign()}
                                            disabled={true}
                                            value= '100'
                                        />                     */}
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                        </>                                          
                    }  
                     <Form.Item
                        name='aproved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={aproved}
                            onChange={onChangeAproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                     <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>                                  
                </Form>
                <span style={{color: 'red'}}>{spanError && spanError}</span>
            </Modal>
        </div>
    )
}

export default PucrchaseSaleCurrencyForm;