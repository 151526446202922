import React, { useContext, useState } from "react";
import './styles.scss'
import { FiltersProps } from "./types";
import { Col, Row, Tooltip } from "antd";
import {
    LeftOutlined,
    RightOutlined
  } from '@ant-design/icons';

export const Filters: React.FC<FiltersProps> = ({
    components,
    height,
    color,
    detailsColor,
    collapsedFilsters,
    setCollapsedFilters,
    tooltip,
    marginTop,
    widhtSections
})=>{

    const handleFilters = ()=>{
        setCollapsedFilters(!collapsedFilsters)
    }

    return(
        <div className={
            collapsedFilsters ? "filters_container" : "filters_container collapsed"}
            style={{
                height: `calc(100% - ${height}px)`,
                marginTop: `${marginTop}px`,
            }}  
        >  
            <Row
                style={{
                  width: widhtSections ? `${widhtSections}px`: '400px', 
                  height:'100%', 
                }}
            >
                <div 
                    onClick={handleFilters}
                    style={{
                        display: 'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        backgroundColor: color ? color : '#121832', // '#37bee070', //'#121832', //
                        height: '50px',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',                     
                        cursor: 'pointer',
                        width: '33px' 
                    }}
                >                     
                    <div
                        style={{
                            display: 'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            backgroundColor: '#37bee070', // '#37bee070', //'#121832', //
                            height: '50px',
                            width: '100%',
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px', 
                            borderBottom:`2px solid ${detailsColor ? detailsColor : '#37bde0' }`,  
                            borderLeft: `1px solid ${detailsColor ? detailsColor : '#37bde0' }` ,                     
                            cursor: 'pointer', 
                        }}
                    >                    
                        { collapsedFilsters ?
                            <RightOutlined               
                                style={{
                                    color: detailsColor ? detailsColor :'#37bde0 ', 
                                    fontSize: '15px',
                                }}
                            /> :
                            <LeftOutlined                  
                                style={{
                                    color: detailsColor ? detailsColor : '#37bde0', 
                                    fontSize: '15px',
                                }}
                            />
                        }                                           
                    </div>                                    
                </div>
                <div
                    style={{
                        backgroundColor:'#121832',
                        height: '100%',
                        width: widhtSections ? `${widhtSections - 33}px` : '367px',                        
                        overflowY: 'auto'
                    }}
                >
                    {components}
                </div>
            </Row>           
        </div>
    )
}

export default Filters