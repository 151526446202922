import React, {
    createContext,
    useState,
  } from 'react';

  class VoidContext implements VariablesContext {
    get collapsedFilsters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setCollapsedFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showForms(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowForms(): never {
      throw new Error('Cannot consume context outside of provider');
    }
 
  }

  interface VariablesContext {
    collapsedFilsters: boolean,
    setCollapsedFilters: Function,
    showForms: ShowFormsProps 
    setShowForms: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface ShowFormsProps{
    deposit_extraction: boolean,
    transferer: boolean,
    buy_and_sell: boolean,
    transfers_between_cashs: boolean,
    windows: boolean,
    usdt: boolean,
    usdt_windows: boolean,
    transfer_to_suppliers: boolean,
    transferer_to_suppliers: boolean,
    funds_movements: boolean
  }
  
  export const VariablesContext = createContext<VariablesContext>(new VoidContext());
  export const VariablesContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [collapsedFilsters, setCollapsedFilters] = useState(false)
    const [showForms, setShowForms] = useState<ShowFormsProps>({
      deposit_extraction: false,
      transferer: false,
      buy_and_sell: false,
      transfers_between_cashs: false,
      windows:false,
      usdt: false,
      usdt_windows: false,
      transfer_to_suppliers: false,
      transferer_to_suppliers: false,  
      funds_movements: false    
    })

 
    return (
      <VariablesContext.Provider value={{
        collapsedFilsters, 
        setCollapsedFilters,
        showForms, 
        setShowForms

      }}>{children}</VariablesContext.Provider>
    );
  };



  