import { BalanceGeneralProps } from "./types"

import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Button, Card, Col, Row, } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { useGetGeneralBalance } from "../../../Services/useGetGeneralsBalance";
import { useGetCurrencyQuote } from "../../../Services/useGetcurrencyQuote";
import BalanceByCurrency from "../../BalancesTable/BalanceByCurrency";
import { SpinerHorizontal } from "../../SpinerHorizontal/SpinerHorizontal";
import BalanceByCheckout from "../../BalancesTable/BalanceByCheckout";
import BalanceByAccount from "../../BalancesTable/BalanceByAccount";

const list_leyend = [
  {
    title: 'Balance',
    color: '#20B2AA'
  },
  {
    title: 'Saldo actual',
    color: '#37bde0'
  },
  {
    title: 'Saldo futuro',
    color: '#FF69B4'
  },
  {
    title: 'Negativo:',
    color: 'red'
  },

]

const Leyend = React.memo(({
}) =>{
  return(
    <Row
      style={{
        padding: '0px 15px'
      }}
    >
      {
        list_leyend.map((item)=>{
          return(
            <Col
              className="center_element"
              style={{
                marginRight: 15,
                flexDirection: 'row',
              }}
            >
              <span style={{color:'gray'}}>{item.title}</span> 
              <div                 
                style={{
                  marginLeft: 5,
                  height: 10,
                  width: 10,
                  backgroundColor: item.color
                }}
              ></div>
            </Col>
          )
        })
      }     
    </Row>
  )
})


const BalanceCard: React.FC<{
  title: string,
  subtitle: string,
  balance: string,
  color: string
}> = ({
  title,
  subtitle,
  balance,
  color
}) =>{
  let set_color = color;
  if (!isNaN(Number(balance)) && Number(balance) < 0) {
    set_color = 'red';
  }
  return(
    <Col span={8}>
      <Card  bordered={false}>
        <Row 
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <span style={{
            color: '#37bde0',
            fontSize: '14px',
            fontWeight: 600
          }}>{title}</span>
          <span style={{
            color: 'gray',
            fontSize: '11px'  
          }}>{subtitle}</span>          
        </Row>
        <Row>
          <span style={{
            color: set_color,
            fontSize: '22px',
            fontWeight: 600,
            paddingTop: 10 
          }}>
            U$D {balance && Number(balance).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </span>          
        </Row>
      </Card>
    </Col>
  )
}

const BalanceGeneral: React.FC<BalanceGeneralProps> = () => {

    const {data: balanceGeneral} = useGetGeneralBalance()    
    const {data: currenciesList} = useGetCurrencyQuote(2)
    const [loadingRefresh, setLoadingRefresh] = useState(false)
    const [ dataCard, setDataCard] = useState<{
      title: string,
      subtitle: string,
      balance: string,
      color: string
    }[]>()

    const updateData = (loadingRefresh: boolean) =>{
      if(loadingRefresh){
        setLoadingRefresh(true)
      }
      if(balanceGeneral){
        setDataCard([
          {
            title: 'BALANCE TOTAL / FONDOS NETOS',
            subtitle: 'Balance de cajas y ctas ctes',
            balance: balanceGeneral.general_balance,
            color: '#20B2AA'
          },
          {
            title: 'BALANCE CAJAS / BANCOS',
            subtitle:'Balance de todas las cajas',
            balance: balanceGeneral.checkout_balance,
            color: '#20B2AA'
          },
          {
            title: 'BALANCE CTAS CTES CLIENTES',
            subtitle:'Balance de todas las cuentas',
            balance: balanceGeneral.account_balance,
            color: '#20B2AA'
          }
        ])
      }
      if(loadingRefresh){
        setLoadingRefresh(false)
      }
    }

    useEffect(()=>{
      updateData(false)
    },[balanceGeneral])

    return( 
      <>  
        <div className='all_cash_balance_section user_section_container'>                                  
            <Row
              className='global_title_section'  
            >
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <h3>REPORTE DE BALANCES</h3> 
                <Button 
                      type='primary'
                      className='allCheckout_section_btn'
                      onClick={()=>updateData(true)}
                      //disabled={!branchSelected}
                      style={{marginLeft: 15}}
                      loading={loadingRefresh}
                  >
                      Refresh                        
                  </Button>  
              </Row>
                            
            </Row>            
            <Row 
              style={{
                  display:'flex',
                  justifyContent: 'center',
                  width: '100%',                  
                  height: '90%',
                  padding: '30px 30px',                        
                  border: '1px solid   #645fca6c',
                  marginTop: '10px',
                  overflowY: 'scroll'
              }}
            >              
              <Col span={20}>
                <Row style={{ width: '100%' }} gutter={16}>
                  {balanceGeneral?.description ? (
                    <Card 
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      title={`No es posible hacer el balance general: ${balanceGeneral?.description?.description}`} 
                    >  
                      <Row
                        style={{
                          width: '100%',
                        }}
                      >
                        <span style={{ color: 'red',  marginRight: 10,  }}>
                          {`Cargue todos los tipos de cambios en:`}
                        </span>                    
                        <span style={{ color: 'red',  marginRight: 10, fontWeight: 600 }}>
                          {`=> ADMINISTRACIÓN => TIPOS DE CAMBIOS.`}
                        </span>                        
                      </Row>   
                      <span style={{ color: 'red',  marginRight: 10, fontWeight: 600 }}>
                          MONEDA PRINCIPAL: DOLAR
                        </span>                  
                    </Card>
                  ) : (
                    balanceGeneral && dataCard &&
                    dataCard?.map((item, index) => (
                      <BalanceCard 
                        key={index}
                        title={item.title}
                        subtitle={item.subtitle}  
                        balance={item.balance}
                        color={item.color}
                      />
                    ))
                  )}
                </Row>
                <Row style={{marginTop: 20}}>
                  <Row                     
                  >
                    <Col>
                      <h4
                        style={{
                          color: 'white',
                          fontWeight: 600
                        }}
                      >
                        BALANCES POR MONEDA
                      </h4>  
                    </Col>
                    <Col className="center_element">
                      <Leyend/>
                    </Col>               
                  </Row>
                  <Row style={{width: '100%'}}>
                    {
                      balanceGeneral 
                        ? <BalanceByCurrency dataApi={balanceGeneral.discriminations}/>
                        : <SpinerHorizontal/> 
                    } 
                  </Row>                                
                </Row>
                <Row style={{marginTop: 20}}>
                  <Row                 
                  >
                    <h4
                    style={{
                      color: 'white',
                      fontWeight: 600
                    }}
                    >BALANCES POR CAJA / BANCOS</h4>                    
                  </Row>
                  <Row style={{width: '100%'}}>
                    {
                      balanceGeneral 
                        ? <BalanceByCheckout dataApi={balanceGeneral.checkouts}/>
                        : <SpinerHorizontal/> 
                    }
                  </Row>                                 
                </Row>
                <Row style={{marginTop: 20}}>
                  <Row                 
                  >
                    <h4
                    style={{
                      color: 'white',
                      fontWeight: 600
                    }}
                    >BALANCES POR CTE CTE CLIENTE</h4>                    
                  </Row>
                  <Row style={{width: '100%'}}>
                    {
                      balanceGeneral 
                        ? <BalanceByAccount dataApi={balanceGeneral.accounts}/>
                        : <SpinerHorizontal/> 
                    } 
                  </Row>                                
                </Row>
              </Col>
              <Col span={4}>
                  <Card
                    title= 'Tipos de cambios'
                    style={{height: '100%'}}
                  >
                    {currenciesList &&
                    
                    currenciesList.map((item, index) =>(
                      <Row key={index}>
                        <span
                          style={{
                            color: '#37bde0',
                            fontSize: '14px',
                            fontWeight: 600,
                            marginBottom: 10,                    
                          }}
                        >{item.secondary_currency.name}</span>:
                        <span
                          style={{
                            color: 'gray',
                            fontSize: '14px',
                            fontWeight: 600,
                            marginLeft: 6

                          }}
                        >
                          {item.secondary_currency?.id !== 3 && item.secondary_currency.sign + ' '} 
                          {item.report_price && item.report_price.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                          {item.secondary_currency?.id == 3 && '%'} 
                        </span>
                      </Row>)
                    )}
                  </Card>
              </Col>                          
            </Row>             
        </div>
      </>
                
)};

export default BalanceGeneral
