
import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {   Row, Table } from 'antd';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { BalanceByCheckoutProps } from './types';
import { CheckoutBalance } from '../../../Services/useCashDesk';


export interface DataType {
  index: string,
  branch_name: string,
  branch_id: string,
  checkout_name: string,
  currency_name: string,
  currency_sign: string,
  currency_id: string,
  balance: string,
  future_balance: string,
}

const HandleRender = React.memo<{
    text: string, 
    record: DataType,   
    color: string
  }>(({text, record, color}) => {
    let set_color = color;
    const cleanedText = text.replace(/\./g, '').replace(',', '.');
    if (cleanedText && !isNaN(Number(cleanedText)) && Number(cleanedText) < 0) {
      set_color = 'red';
    }
  return(
    
    <span 
      style={{
        color: set_color,
        fontWeight: 600
      }}
    >
      {text && text !== '' && record.currency_sign} {text}
    </span>
  )
})

const BalanceByCheckout: React.FC<BalanceByCheckoutProps> = ({
  dataApi
}) => {

  const {adjustedTableHeigthScroll} = useContext(StylesContext)    
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])  

  useEffect(()=>{
    setLoadingTableData(true)
    if(dataApi){
      rowData(dataApi) 
      setLoadingTableData(false)
    }
  },[dataApi])  

  const rowData = (data: CheckoutBalance[]) => {
    let list: CheckoutBalance[] = data ?? dataApi; // Usa nullish coalescing operator (??)
  
    if (list) {
      const filterData: DataType[] = list.map((data, index) => ({
        index: index.toString(),
        branch_name: data.branch?.name || '',
        branch_id: data.branch?.id?.toString() || '',
        checkout_name: data.name || '',
        currency_name: data.currency.name || '',
        currency_sign: data.currency.sign || '',
        currency_id: data.currency.id.toString(),
        balance: Number(data.balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
        future_balance: Number(data.future_balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
      }));
  
      // Ordenar por branch_id de menor a mayor
      filterData.sort((a, b) => Number(a.branch_id) - Number(b.branch_id));
  
      setDataTable(filterData);
    }
  };

    const filters = (type: 'branch' | 'currency' ) => {
      let textAndValue: { text: string, value: string }[] = [];
      let uniqueValues = new Set();
  
      dataApi?.forEach((item) => {
          let code =  ''
          let value = ''

          switch(type){
            case 'branch':
              code = item.branch.name 
              value = item.branch.name 
              break;
            case 'currency':
              code = item.currency.name
              value = item.currency.id.toString()
              break;
          }
  
          if (!uniqueValues.has(code) ) {
              uniqueValues.add(code);
              textAndValue.push({
                  text: code,
                  value: value
              });
          }
      });

      return textAndValue;
    }
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Sucursal',
            dataIndex: 'branch_name',
            filters: filters('branch'),
            onFilter: (value, record) => typeof value === 'string' && record.branch_name == value ,
            filterSearch: true,
        },      
        {
          title: 'Caja / Bancos',
          dataIndex: 'checkout_name',
          filters: filters('currency'),
          onFilter: (value, record) => typeof value === 'string' && record.currency_id == value ,
          filterSearch: true,
        },
        {
          title: 'Saldo',
          dataIndex: 'balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#37bde0'/>,
        },
        {
          title: 'Saldo futuro',
          dataIndex: 'future_balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#FF69B4'/>,
        },      
    ];
    

    return( 
      <>                                                         
        <Row 
          className='balance_by_checkout'
          style={{
              display:'flex',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              paddingRight: 16,                
          }}
        >
          {
            loadingTableData
            ? <SpinerHorizontal/> 
            : <Table 
                style={{
                    width: '100%',
                }}
                columns={columns} 
                dataSource={dataTable}  
                pagination={false} 
            />    
          } 
        </Row>  
      </>                
)};

export default BalanceByCheckout
