import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import {  Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { USDTWindowsProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { FormItemsBuy } from "./FormItemsBuy";
import { FormItemsSell } from "./FormItemsSell";
import { ResponseAccounts,  } from "../../../Services/useAccounts";
import './styles.scss';
import { useOperations } from "../../../Services/useOperations";
import { useCurrency } from "../../../Services/useCurrency";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

interface Currency{
    id: string,
    sign: string,
    name: string
}

export interface ValuesForm {
    operation_type: FilterValues,
    full_name: string,   
    sell_currency: FilterValues,
    extraction_cash: FilterValues,
    buy_currency: FilterValues,
    deposit_cash: FilterValues,
    type_change: string,
    amount: string,
    description: string,
    approved: boolean,
    comission: number
}

export const USDTWindows = React.memo<USDTWindowsProps>(({
    form_buy_and_sell,
    rowSelect,
    setRowSelect,
})=>{
    const {data: clients} = useGetAllClients()    
    const {createOperation, editOperation, getOperation} = useOperations()
    const {getExchangeRate} = useCurrency()
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms} = useContext(VariablesContext) 
    const { userData } =useContext(UserContext)    
    const { Option } = Select;
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [operationType, setOperationType] = useState<'sell' | 'buy' | undefined>()
    const [aproved, setAproved] = useState(false)    
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()
    const [count, setCount] = useState(0)
    const [secondCurrency, setSecondCurrency] = useState<Currency>()
    const [result, setResult] = useState<number>()
    const [amount, setAmount] = useState<number>()
    const [typeChange, setTypeChange] = useState<number>()
    const [comision, setComision] = useState<number>()
    const [customerSelected, setCustomerSelected] = useState<{
        name: string,
        id: string,
    }>()

    const [mainCurrency] = useState<Currency>({
        id: '3',
        name: 'Thether',
        sign: 'USDT'
    })
       
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    useEffect(()=>{
        if(secondCurrency && secondCurrency.id == '2'){
            if(amount && comision){
                let total = amount * (1 + comision / 100)
                setResult(amount * (1 + comision / 100))
            }
        }else{
            if(typeChange && amount && comision){
                let total = amount * (1 + comision / 100)
                setResult(typeChange*total)
            }
        }        
    },[amount, typeChange, comision, secondCurrency])

        
    useEffect(()=>{
        if(rowSelect){
            uploadData()
            
        } else{            
            form_buy_and_sell.resetFields()
            setSecondCurrency(undefined)
        }      
    },[])

    useEffect(()=>{
        if(secondCurrency){
            if(rowSelect){
                setCount(2)
                if(count > 1){
                    checkExchangeRate()                 
                }
            }else{
                checkExchangeRate()               
            } 
        } 
             
    },[secondCurrency])

    
    const uploadData = async() =>{
        let operationAmount : string = ''
        let type_change: string = ''

        if(rowSelect && rowSelect.id){
            const response = await getOperation(+rowSelect.id)
            if(response){
                setOperationType(response.currency_sale ? 'sell' : 'buy') 
                setSecondCurrency({
                    id: response.secondary_currency.id.toString(),
                    sign: response.secondary_currency.name.toString(),
                    name: response.secondary_currency.name.toString()
                })                  
                if(response.currency_quote_buy_price){
                    type_change = response.currency_quote_buy_price.toString()
                }
                if(response.currency_quote_sell_price){
                    type_change = response.currency_quote_sell_price.toString()
                }                
                
                operationAmount = response.currency_sale 
                ? response.credit.toString()
                : !response.currency_sale
                    ? response.debit.toString()
                    : ''   
                    
                form_buy_and_sell.setFieldsValue(
                    { 
                        operation_type: {
                            value: operationType, 
                            label: response.currency_sale ? 'Venta' : 'Compra'  
                        },
                        full_name: response.full_name,
                        deposit_cash:{
                            value: response.destination_checkout?.name || undefined,
                            label: response.destination_checkout?.name || undefined,
                            key: response.destination_checkout?.id|| undefined,
                        },
                        extraction_cash:{
                            value: response.origin_checkout?.name || '',
                            label: response.origin_checkout?.name|| '',
                            key: response.origin_checkout?.id || '',
                        },
                        branch_cahs:{
                            value: response.destination_checkout?.branch?.id|| undefined,
                            label:  response.destination_checkout?.branch?.name || undefined,
                            key: response.destination_checkout?.branch?.name || undefined,
                        },
                        branch_cahs_extraction:{
                            value: response.origin_checkout?.branch?.id|| undefined,
                            label:  response.origin_checkout?.branch?.name || undefined,
                            key:  response.origin_checkout?.branch?.name || undefined,
                        },
                        comission: response.usdt_quote_percentage || '',
                        type_change: type_change,
                        amount: operationAmount,
                        description: response.description
                    }
                )   
                if(type_change){
                    setTypeChange(Number(type_change))
                }
                if(response.usdt_quote_percentage){
                    setComision(response.usdt_quote_percentage)
                }
                if(operationAmount){
                    setAmount(Number(operationAmount))
                } 
            }            
            setCount(1)
        }              
    }
      
    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        setLoadingBtn(false)
        form_buy_and_sell.resetFields()
        if(setRowSelect){
            setRowSelect(undefined)
        }
    }

    // const getBalance = async() =>{   
    //     const data = await getAccountsCurrencyAndCustomer(Number(client), Number(currency?.id))
    //     if(data){
    //         setBalance(data)
    //     }        
    // }

    const checkExchangeRate = async()=>{
        let main_currency_id = '2' //'usd'
        
        if(secondCurrency && secondCurrency.id !== '2' ){ 
            const data = await getExchangeRate( +main_currency_id, +secondCurrency.id)
            if (data){
                switch(operationType){
                    case 'buy':
                        form_buy_and_sell.setFieldValue('type_change', data.buy_price)
                        setTypeChange(data.buy_price)
                        break;
                    case 'sell':
                        form_buy_and_sell.setFieldValue('type_change', data.sell_price)
                        setTypeChange(data.sell_price)
                        break;
                    default:
                        break;
                }                    
            }            
        }        
    }

    const clearState = () =>{
        setSecondCurrency(undefined)
        setBalance(undefined)
        setResult(undefined)
        setTypeChange(undefined)
        setComision(undefined)
        setResult(undefined)
        setAmount(undefined)
        form_buy_and_sell.resetFields([
            'client', 
            'deposit_cash',
            'buy_currency', 
            'deposit_cash', 
            'sell_currency', 
            'extraction_cash', 
            'comission',
            'type_change', 
            'aproved', 
            'amount', 
            'description',
            'branch_cahs',
            'branch_cahs_extraction',
        ]);
    }
      
    const handleSave = async (values: ValuesForm)=>{
        setLoadingBtn(true)
        if (!secondCurrency) {
            if (operationType === 'sell') {
                form_buy_and_sell.setFields([
                    {
                        name: 'buy_currency', 
                        errors: ['Este campo es obligatorio'],
                    },
                ]);
                setLoadingBtn(false)
                return;
            } else if (operationType === 'buy') {
                form_buy_and_sell.setFields([
                    {
                        name: 'sell_currency',
                        errors: ['Este campo es obligatorio'],
                    },
                ]);
                setLoadingBtn(false)
                return;
            }
        } 
        if(secondCurrency && userData){
            let operationData={
                description: values.description || '',
                type_id: 9, //id de compra venta usdt              
                main_currency_id: +mainCurrency.id,
                secondary_currency_id: +secondCurrency.id , 
                user_id: userData.id,
                debit: Number(values.amount),
                currency_quote_sell_price: operationType =='sell' ? Number(values.type_change) : undefined,
                currency_sale: operationType =='sell' ? true : false,
                approved: aproved,
                full_name: values.full_name ,                
                currency_quote_buy_price: operationType =='buy' ? Number(values.type_change) : undefined ,                    
                origin_checkout_id: values.extraction_cash ? Number(values.extraction_cash.key) : undefined, //Hubo extracción en cuenta  
                destination_checkout_id: values.deposit_cash?.key ?  Number(values.deposit_cash.key) : undefined, //Hubo depósito en cuenta       
                usdt_sale:  operationType =='sell' ? true : false,   
                usdt_quote_percentage: values.comission ? values.comission : undefined,
                windows_operation: true             
            }
            if(rowSelect){
                await editOperation(handleCancel, rowSelect.id!, operationData)
            }else{
                await createOperation(handleCancel, operationData)
            }
            setCollapsedFilters(!collapsedFilsters)  
        }                       
    }  
    
    const handleOperationType = (event:{value:'sell' | 'buy' } ) =>{
        if(event){
            setOperationType(event.value)
        }
        clearState()  
        setAproved(false)     
    }   

    const handleChangeClients = (values: {
        label: string,
        value: string,
        key: string
    }) =>{
        setCustomerSelected({
            id: values.key,
            name: values.label
        })
    }

    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };


    
     
    return(
        <div>            
            <Modal
                title= 'Compra / Venta: USDT por ventanilla'
                open={showForms.usdt_windows }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_buy_and_sell.submit()}
            >
                <Form
                    form={form_buy_and_sell}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >      
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            placeholder='Seleccione el tipo de operación'   
                            disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}
                            onChange={(event)=> handleOperationType(event)}          
                            options={[
                                { value: 'sell', label: 'Venta' },
                                { value: 'buy', label: 'Compra' },
                            ]}                   
                        />
                    </Form.Item> 
                     <Form.Item
                        label='Nombre y apellido'
                        name='full_name'
                        rules={mandatory} 
                    >
                        <Input
                            placeholder="Nombre y apellido"
                        />             
                    </Form.Item>   
                    {
                        operationType && 
                        <>
                            {  
                               operationType == 'buy'
                                ? 
                                    <>
                                        <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSecondCurrency={setSecondCurrency}  
                                            secondCurrency={secondCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance} 
                                            is_main= {true}  
                                            rowSelect={rowSelect}                                   
                                        />
                                        <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSecondCurrency={setSecondCurrency}  
                                            secondCurrency={secondCurrency} 
                                            is_main= {false}    
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                    </>                                    
                                :      
                                    <>              
                                         <FormItemsSell  
                                            form={form_buy_and_sell}
                                            setSecondCurrency={setSecondCurrency}  
                                            secondCurrency={secondCurrency}   
                                            is_main= {true}  
                                            rowSelect={rowSelect}                                                                                    
                                        />
                                       <FormItemsBuy 
                                            form_buy_and_sell={form_buy_and_sell}     
                                            setSecondCurrency={setSecondCurrency}  
                                            secondCurrency={secondCurrency}
                                            customerSelected={customerSelected}       
                                            balance={balance}
                                            setBalance={setBalance}    
                                            is_main= {false}    
                                            rowSelect={rowSelect}                               
                                        />
                                    </>
                            }
                             <Form.Item
                                label= '% de cotización'         
                                name = 'comission'
                                className="sell_and_buy_divide" 
                                rules={mandatory} 
                            >
                                <InputNumber
                                    addonBefore={'%'}
                                    onChange={(e)=>setComision(Number(e))}
                                />
                            </Form.Item>

                            {
                                secondCurrency &&  secondCurrency.id !== '2'  &&                             
                                    <Form.Item
                                        label= 'Tipo de cambio'         
                                        name= 'type_change'
                                        className="sell_and_buy_divide" 
                                        rules={mandatory} 
                                    >
                                        <InputNumber
                                            addonBefore={secondCurrency ? secondCurrency.sign : ' ¤ ' }
                                            onChange={(e)=>{setTypeChange(Number(e))}}
                                        />
                                    </Form.Item>
                            }
                            {
                                <Row
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        gap: 4
                                    }}
                                >
                                    <Col style={{ flex: 1 }}>
                                        <Form.Item
                                            label= {operationType == 'buy' ? 'Monto de compra' : 'Monto de venta'}         
                                            name= 'amount'
                                            rules={mandatory}
                                        >
                                            <InputNumber
                                                addonBefore={'USDT'}
                                                onChange={(e)=>{setAmount(Number(e))}}
                                            />                    
                                        </Form.Item>

                                    </Col>
                                    <Col style={{ flex: 1 }}>
                                        <Form.Item
                                            label= 'Resultado'         
                                            name= 'result'
                                            style={{marginLeft: 20}}
                                        >
                                            <span style={{marginRight: 10, fontWeight: 700}}>{secondCurrency && secondCurrency.sign && secondCurrency.sign}</span>
                                            <span style={{fontSize: 15, fontWeight: 700}}>
                                                {result && Number(result).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </span>
                                            
                                            {/* <InputNumber
                                                addonBefore={amountSign()}
                                                disabled={true}
                                                value= '100'
                                            />                     */}
                                        </Form.Item>
                                    </Col>

                                    
                                </Row>
                            }
                            
                           
                        </>                                          
                    }  
                     <Form.Item
                        name='aproved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={aproved}
                            onChange={onChangeAproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                     <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>                                  
                </Form>
            </Modal>
        </div>
    )
})

export default USDTWindows;

           
