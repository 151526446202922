import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CURRENCY_QUOTE_BY_MAIN_CURRENCY } from '../Axios/ENDPOINTS';
import { ExchangeRateProps } from './useGetExchangeRate';



export const useGetCurrencyQuote= (
    currency_id: number
) => {
    
    const {data, error, mutate }:{
      data: ExchangeRateProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([`${END_POINT_CURRENCY_QUOTE_BY_MAIN_CURRENCY}/${currency_id}` ])    

  
    return {
      data,
      mutate,
      error
    };
};