import React, {
    createContext,
    useState,
  } from 'react';

  class VoidContext implements BalancesContext {
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutateBalance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutateBalance(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  export interface DataType {
    index: number,
    id: number,
    checkout: string,
    balance: string,
    future_balance: string,
    branch_name:string,
    branch_id: string,
    currency_sign: string
    currency_id: string

  }

  interface BalancesContext {
    dataTable: DataType[],
    setDataTable: Function,
    mutateBalance: boolean,
    setMutateBalance: Function
  }



  interface propsWithChildren {
    children: React.ReactNode
  }


  
  export const BalancesContext = createContext<BalancesContext>(new VoidContext());
  export const BalancesContextProvider= ({ children }: propsWithChildren): JSX.Element => {
  
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [mutateBalance, setMutateBalance] = useState(false)

 
    return (
      <BalancesContext.Provider value={{
        dataTable, 
        setDataTable,
        mutateBalance, 
        setMutateBalance

      }}>{children}</BalancesContext.Provider>
    );
  };



  