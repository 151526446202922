import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    DeleteOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { ExchangeRateSectionsProps } from './types';
import { ExchangeRateProps, useGetExchangeRate } from '../../../Services/useGetExchangeRate';
import { useExchangeRate } from '../../../Services/useExchangeRate';
import ExchangeRateForm from '../../Forms/ExchangeRateForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
  index: string,
  id: string,
  main_currency_id: string,
  main_currency_sign: string,
  second_currency_id: string,
  second_currency_name: string,
  sell: string,
  buy: string,
  report_price: string
}

const ExchangeRateSection: React.FC<ExchangeRateSectionsProps> = () => {

    const {data: dataApi } = useGetExchangeRate()
    const {deleteExchangeRate} = useExchangeRate()
    const {adjustedTableHeigthScroll} = useContext(StylesContext) 

    const [form] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
  },[dataApi])  

  const rowData = (data? : ExchangeRateProps[]) =>{
      let filterData: DataType[]= []
      let list: ExchangeRateProps[] = data ? data : dataApi
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index.toString(),
            id: data.id.toString(),
            main_currency_id: data.main_currency?.id?.toString() || '',
            main_currency_sign: data.main_currency?.sign || '',
            second_currency_id: data.secondary_currency?.id?.toString() || '',
            second_currency_name: data.secondary_currency?.sign || '',
            sell: data.sell_price?.toString() ||'',
            buy: data.buy_price?.toString() || '',
            report_price: data.main_currency?.id == 2 && data.report_price 
              ? data.report_price?.toString() 
              : ''
          })
        })                  
      }       
      setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)
        setOpenForm(false)
        setShowConfirmDelete(false)
    }

    const handleRenderer = (text: string, record: DataType) =>{
      return (
          <div className='currency_action_action_render'>
              <DeleteOutlined 
                  onClick={()=>{
                      setRowSelect(record)
                      setShowConfirmDelete(true)
                  }}
                  style={{marginLeft:'30px', color: '#37bde0'}} /> 
          </div>  
      )      
    }
    const ModalConfirmDelete = () => {
      return(
          <Modal
              title="Eliminar"
              open={showConfirmDelete}
              onOk={()=>{
                  if(rowSelect && rowSelect.id){
                    deleteExchangeRate(callback, +rowSelect.id)
                  }}}
              onCancel={()=>setShowConfirmDelete(false)}
              okText="Eliminar"
              cancelText="Cancelar"
          >
              <p>¿Estás seguro de que desea eliminar {rowSelect?.second_currency_name}?</p>
          </Modal>
      )
  }

  const filters = (type: 'main_currency' | 'second_currency'  ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    dataApi?.forEach((item) => {
        let code  
        let value
        switch(type){
          case 'main_currency':
            code = item.main_currency.sign
            value = item.main_currency.id.toString()
            break;
          case 'second_currency':
            code = item.secondary_currency.sign
            value = item.secondary_currency.id.toString()
            break;  
          default:
            code = ''
            value = ''
            break;
        }
        if (!uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: value
            });
        }
    });
    return textAndValue;
  }

  const columns: ColumnsType<DataType> = [
      {
          title: 'Moneda principal',
          dataIndex: 'main_currency_sign',
          key: '1',
          className:'cursor-pointer',
          sorter: (a, b) => a.main_currency_sign.localeCompare(b.main_currency_sign),
          filters: filters('main_currency'),
          onFilter: (value, record) => typeof value === 'string' && record.main_currency_id == value,
          filterSearch: true, 
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Moneda secundaria',
          dataIndex: 'second_currency_name',
          key: '2',
          className:'cursor-pointer',
          sorter: (a, b) => a.second_currency_name.localeCompare(b.second_currency_name),
          filters: filters('second_currency'),
          onFilter: (value, record) => typeof value === 'string' && record.second_currency_id == value,
          filterSearch: true, 
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Venta',
          dataIndex: 'sell',
          key: '3',
          className:'cursor-pointer',
          sorter: (a, b) => Number(a.sell) - Number(b.sell),
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },        
      {
          title: 'Compra',
          dataIndex: 'buy',
          key: '4',
          className:'cursor-pointer',
          sorter: (a, b) => Number(a.buy) - Number(b.buy),
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Precio para balance',
          dataIndex: 'report_price',
          key: '5',
          className:'cursor-pointer',
          sorter: (a, b) => Number(a.report_price) - Number(b.report_price),
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
        title: 'Acciones',
        dataIndex: 'id',
        className:'cursor-pointer',
        render:  handleRenderer,
        key: '6',
        width: 110,      
    }, 
  ];
  
  const onClickRow = (record: DataType) =>{         
    setRowSelect(record)
      setOpenForm(true) 
  }  
    

    return( 
        <>
          <ModalConfirmDelete/>
          { openForm && <ExchangeRateForm
              openForm={openForm} 
              callback={callback}
              rowSelect={rowSelect}
              form={form}
          /> }     
          <div className='currency_section currency_section_container'>                                  
          <Row
                 className='global_title_section'  
              >
                  <h3>TIPO DE CAMBIO</h3>                
              </Row>
              <Row 
                className='global_button_box'
              >
                <Button
                  type='primary'
                  className='movements_section_btn'
                  onClick={()=>{setOpenForm(true)}}
                  >
                    Nuevo tipo de cambio
                </Button>                
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '80%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '80%',
                          marginTop: '2%'
                      }}
                      columns={columns} 
                      dataSource={dataTable}  
                      scroll={{ y: adjustedTableHeigthScroll }}                                   
                      pagination={{
                          pageSize: 50,
                          showSizeChanger: false,                                      
                      }}
                    />    
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default ExchangeRateSection;