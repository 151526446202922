import { useContext, useEffect } from 'react';
import useSWR, { KeyedMutator } from "swr";

import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CURRENCY_QUOTE_GET_ALL } from '../Axios/ENDPOINTS';

export interface ExchangeRateProps{
  id: number,
  main_currency: {
    id: number,
    name: string,
    sign: string
  },
  secondary_currency: {
    id: number,
    name: string,
    sign: string
  },
  buy_price: number,
  sell_price: number,
  report_price: number 
}

export interface ResponseGetExchangeRate{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: ExchangeRateProps[]  
}

export const useGetExchangeRate = () => {
    
    const {data, error, mutate }:{
      data: ExchangeRateProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_CURRENCY_QUOTE_GET_ALL])    

  
    return {
      data,
      mutate,
      error
    };
};