import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CASH_DESK_GET_ALL } from '../Axios/ENDPOINTS';
import { BranchProps } from './useGetBranch';

export interface CashDeskBankProps {
  id: number;
  description: string;
  branch: BranchProps;
  name: string;
  currency: {
    id: number,
    name: string;
    sign: string;
  };
  balance: number;
}


export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: CashDeskBankProps[]  
}

export const useGetCheckoutGetAll = () => {
    
    const {data, error, mutate }:{
      data: CashDeskBankProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_CASH_DESK_GET_ALL])    
  
    return {
      data,
      mutate,
      error
    };
};