import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form, Tooltip } from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    WarningOutlined,
    DatabaseOutlined,
    CloseOutlined,
    UndoOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps, ValuesForm } from './types';
import { Transaction } from '../../../Services/useGetMovements';
import { useGetAllClients } from '../../../Services/useGetAllClients';
import { useGetCurrency } from '../../../Services/useGetCurrency';
import DepositExtractionForm from '../../Forms/DepositExtractionForm';
import PucrchaseSaleCurrencyForm from '../../Forms/PucrchaseSaleCurrencyForm';
import { useOperations } from '../../../Services/useOperations';
import TransfersBetweenAccounts from '../../Forms/TransfersBetweenAccounts';
import moment from "moment";
import { useGetOperationType } from '../../../Services/useGetOperationType';
import { DatePicker, Space } from 'antd';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { OperationsContext } from '../../../Contexts/UserContext/OperationsContext';
import { VariablesContext } from '../../../Contexts/UserContext/VariablesContext';
import { DataType } from '../../../Contexts/UserContext/MovementAcountContext';
import { RenderApproved } from '../../RenderApproved';
import TransfersBetweenBoxes from '../../Forms/TransfersBetweenBoxes';
import Windows from '../../Forms/Windows';
import USDT from '../../Forms/USDT';
import TransfersSuppliers from '../../Forms/TransfersSuppliers';
import RenderOperationType from '../../RenderOperationType';
import { useGetBranch } from '../../../Services/useGetBranch';
import { useGetCashDesk } from '../../../Services/useGetCashDesk';
import USDTWindows from '../../Forms/USDTWindows';
import { RenderVentanilla } from '../../TableRenders';
import FundsMovementForm from '../../Forms/FundsMovementForm';

const RenderNumOperations = React.memo((value: {text: any, record: DataType})=>{
  return(
      <Row
          justify={
            value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
              ? 'space-between'
              : 'start'
          }
          style={{fontSize: 12}}
      >
          <span >{value.text}</span>            
          {value.record.deleted === 'true' && 
              <Tooltip
                  title='Eliminado'
              >
                  <CloseOutlined style={{color: 'red'}}/>
              </Tooltip>                
          }
           {value.record.is_deleting_operation === 'true' && 
              <Tooltip
                  title='Nota de crédito'
              >
                  <UndoOutlined style={{color: 'red'}}/>  
              </Tooltip>                
          }
      </Row>
      
  )
})

const RenderCustomerDestination = React.memo((value:{text: any, record: DataType})=>{ 
  let data =  value.record
  let customer = ''
  let showCustomer: boolean = true

  // if( (data.operation_type_id == '1' || data.operation_type_id == '2')){
  //   if(data.is_sale || data.usdt_sale){
  //     showCustomer = false
  //   }
  // }
 
  if(data.destination_account_customer_id && data.destination_account_customer_id !== '0' && showCustomer){
    customer = data.destination_account_customer_name + ' ' + data.destination_account_customer_last_name +' - ' + `[${data.destination_account_currency_sign}]`
  }
    return(
      <span>{customer}</span>
    )
  }
)

const RenderCustomerOrigin = React.memo((value:{text: any, record: DataType})=>{ 
  let data =  value.record
  let customer = ''
  let showCustomer: boolean = true

  // if( (data.operation_type_id == '1' || data.operation_type_id == '2')){
  //   if(data.is_sale || data.usdt_sale){
  //     showCustomer = false
  //   }
  // }
 
  if(data.origin_account_customer_id && data.origin_account_customer_id !== '0' && showCustomer){
    customer = data.origin_account_customer_name + ' ' + data.origin_account_customer_last_name +' - ' + `[${data.origin_account_currency_sign}]`
  }
    return(
      <span>{customer}</span>
    )
  }
)


const formatted = (value: string)=>{

  const number = parseFloat(value);

  let data =  new Intl.NumberFormat('es-AR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return  data
}

const RenderAmount = React.memo(
  ({ text, record, type }: { text: string; record: DataType; type: 'credit' | 'debit' }) => {
    let sign = '';

    if (type === 'credit') {
      if(record.operation_type_id === '7' && record.windows_operation === 'true'){ //si es ventanilla uso la moneda de la caja
        sign = record.destination_checkout_currency_sign || ''
      }else{
        sign = record.destination_account_currency_sign || ''
      }
    } else if (type === 'debit') {
      if(record.operation_type_id === '7' && record.windows_operation === 'true'){ //si es ventanilla uso la moneda de la caja
        sign = record.origin_checkout_currency_sign || ''
      }else{
        sign = record.origin_account_currency_sign || ''
      }
    }

    if(record.operation_type_id !== '8' && text == '0.00'){
      text = ''
    }else{
      text = formatted(text)
    }

    return <span>{type == 'debit' && sign && '-'} {sign}{text}</span>;
  }
);

const RenderCheckout = React.memo((value:{text: string, record: DataType})=>{
  let txt =''
  if(value.record.operation_type_id === '7' && value.record.windows_operation === 'true'){
    txt = value.text
  }
  if(value.record.operation_type_id !== '9' && value.record.operation_type_id !== '7'){
    txt = value.text
  }
  return(<div>{txt} </div>)
}) 



const OperationSections: React.FC<UsersSectionProps> = () => {
  
  const handleRenderer = (text: string, record: DataType) =>{
    return (
      <div className='currency_action_action_render'>
        {
          record.deleted == 'true' || 
          record.is_deleting_operation == 'true' ||
          record.related_operation_id 
          ? <div></div>
          : <DeleteOutlined 
            onClick={()=>{
              setRowSelect(record)
              setShowConfirmDelete(true)
            }}
          style={{color: '#37bde0'}} />           
        }            
      </div>  
    )      
  }

  const filters = (
      type: 'n°' 
      | 'operation_type_name' | 'approved' | 'deleted' | 'is_deleting_operation' | 'debit' 
      | 'credit'| 'destination_account_id'| 'origin_account_id' | 'destination_checkout_name' | 'origin_checkout_name'
      | 'full_name',
      dataApi: Transaction[]
  ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    dataApi?.forEach((item) => {
        let code = ''
        let value = ''
        switch(type){
            case 'n°':
                code = item.operation_id?.toString()
                value =  item.operation_id?.toString()
                break;
            case 'operation_type_name':
                code = item.type.name;
                value = item.type.name;
                break;
            case 'approved':
                code = item.approved ? 'Aprobado' : 'No aprobado';
                value = item.approved ? 'true' : 'false';
                break; 
            case 'deleted':
                code = item.deleted ? 'Eliminado' : 'No eliminado';
                value = item.deleted ? 'true' : 'false';
                break;    
            case 'is_deleting_operation':
                code = item.is_deleting_operation ? 'Nota de crédito' : 'No NC';
                value = item.is_deleting_operation ? 'true' : 'false';
                break;  
            case 'debit':
              code = item.debit?.toString();
              value = item.debit.toString();
              break;  
            case 'credit':
              code = item.credit?.toString();
              value = item.credit?.toString();
              break;                 
            case 'full_name':
                code = item.full_name;
                value = item.full_name;
                break; 
            case 'destination_account_id':
              const firstName = item.destination_account?.customer?.first_name;
              const lastName = item.destination_account?.customer?.last_name;
              const customer_id = item.destination_account?.customer?.id
          
              if (firstName && lastName && customer_id) {
                  code = `${firstName} ${lastName}`;
                  value = customer_id?.toString();
              }
              break;  
            case 'origin_account_id':
              const firstName_1 = item.origin_account?.customer?.first_name;
              const lastName_1 = item.origin_account?.customer?.last_name;
              const currencySign_1 = item.origin_account?.currency?.sign;
              const oroginAccountId = item.origin_account?.id?.toString();
          
              if (firstName_1 && lastName_1 && currencySign_1 && oroginAccountId) {
                  code = `${firstName_1} ${lastName_1} ${currencySign_1}`;
                  value = oroginAccountId;
              }
              break;   
            case 'destination_checkout_name':
              const name = item.destination_checkout?.name           
              if (name) {
                  code = `${name}`;
                  value = item.destination_checkout?.id?.toString();
              }
              break;  
            case 'origin_checkout_name':
              const name_destination = item.destination_checkout?.name           
              if (name_destination) {
                  code = `${name_destination}`;
                  value = item.destination_checkout?.id?.toString();
              }
              break;                 
            default:
                code ='';
                value = ''
                break               
        }
      
        if (code && !uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: value
            });
        }
    });

    textAndValue.sort((a, b) => {
      if (!isNaN(Number(a.text)) && !isNaN(Number(b.text))) {
          return Number(a.text) - Number(b.text); // Para números
      } else {
          return a.text.localeCompare(b.text); // Para strings
      }
    });
    return textAndValue;
  }

  const { data: clients } = useGetAllClients()
  const { data: currencies } = useGetCurrency()
  const { Option } = Select;
  const { getOperationAmount, deleteOperation } = useOperations()
  const { data: operation_type } = useGetOperationType()
  const { RangePicker } = DatePicker;
  const { adjustedTableHeigthScroll } = useContext(StylesContext)  
  const { setShowForms, showForms } = useContext(VariablesContext)
  const { data: cashs } = useGetCashDesk()
  const { 
    setDataApi, 
    columnsOperationSections, 
    setColumnsOperationSections,
    approvedKey, 
    setApprovedkey,
    deletedKey, 
    setDeletedkey,
    rowSelect,
    setRowSelect,
    showConfirmDelete,
    setShowConfirmDelete
  } = useContext(OperationsContext)

  const [form_deposit_extraction] = useForm()
  const [form_transferers] = useForm()
  const [form_buy_and_sell] = useForm()
  const [form_usdt_windows] = useForm()
  const [form_operations_filters] = useForm()
  const [transfers_between_cashs] = useForm()
  const [transferer_to_suppliers] = useForm()
  const [form_funds_movements] = useForm()
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [loadingBtn, setLoadingBtn] = useState<{
      search: boolean,
      refresh: boolean
  }>({
      search: false,
      refresh: false
  })

  const [selectedBranch, setSelectedBranch] = useState<number>()

  const {data: branches } = useGetBranch()
    
  const makeColumns = (dataApi: Transaction[])=>{
    if(dataApi){
      const newColumns: ColumnsType<DataType> = [
        {
          title: 'N° ',
          dataIndex: 'operation_id',
          width: 80,
          fixed: 'left',
          className:'cursor-pointer ', 
          render: (text, record)=><RenderNumOperations text={text} record={record}/> ,
          filters: filters('n°', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.operation_id == value,
          filterSearch: true, 
        },
        {
          title: 'OA',
          dataIndex: 'associated_operation',
          width: 70,
          fixed: 'left',
        },
        {
          title: 'Usuario',
          dataIndex: 'show_user', 
          width: 120,  
          ellipsis: true         
        },  
        {
            title: 'Fecha',
            dataIndex: 'date',
            width: 100,
            className:'cursor-pointer text-center', 
            onFilter: (value, record) => typeof value === 'string' && record.date == value,
            filterSearch: true,     
        },
        {
          title: 'Hora',
          className:'cursor-pointer text-center',
          dataIndex: 'hours',
          width: 100,      
        },
        {
          title: 'Ventanilla',
          dataIndex: 'full_name',
          ellipsis: true,
          width: 150,
          className:'cursor-pointer cell_window',
          render: (text: string, record: DataType) => <RenderVentanilla  text={text} record={record} />,
          filters: filters('full_name', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.full_name == value,
          filterSearch: true,
        },
        {
          title: 'Aprobado',
          dataIndex: 'approved',
          render: (text: string, record: DataType)=> 
            <RenderApproved 
              text={text} 
              record={record} 
              setDataTable={setDataTable} 
              dataTable={dataTable} 
              callback ={handleSearch}
            />,
          width: 100,
          className:'cursor-pointer', 
          filters: filters('approved', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.approved == value,      
        },     
        {
          title: 'Operación',
          dataIndex: 'operation_type_name',
          width: 190,
          className: 'cursor-pointer',
          filters: filters('operation_type_name', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.operation_type_name == value,
          render: (text, record)=>{return<RenderOperationType text={text} record={record}/>},
        },      
        {
          title: 'Tipo de cambio',
          dataIndex: 'currency_quote',
          className: 'text-center',
          width: 110,
        },
        {
          title: 'TC USDT',
          dataIndex: 'porcentage_currency_quote',
          className: 'text-center',
          width: 70,
        },
        {
          title: 'Crédito',
          dataIndex: 'credit', 
          width: 130,   
          className:'cursor-pointer  text-right  cell_credit background_cell_credit',
          filters: filters('credit', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.credit == value,
          filterSearch: true,
          render:(text: string, record: DataType)=><RenderAmount text={text} record={record} type={'credit'}/>,
        },  
    
        {
          title: 'Cliente', //Cuenta destino
          dataIndex: 'destination_account_id',
          render: (text, record)=><RenderCustomerDestination text={text} record={record}/>,
          className:'cursor-pointer text-right cell_border_left   background_cell_credit',  
          ellipsis: true,
          width: 150,   
          filters: filters('destination_account_id', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.destination_account_customer_id == value,
          filterSearch: true,
        },
        {
          title: 'Sucursal - Caja',
          dataIndex: 'destination_checkout_branch', 
          render: (text, record)=>{ 
            let txt = record.destination_checkout_branch !== '' && record.destination_checkout_name !== '' 
              ? record.destination_checkout_branch + ' - ' + record.destination_checkout_name
              : ''
            return <RenderCheckout text={txt} record={record}/>}, 
          className: ' text-right cell_border_left background_cell_credit',
          width: 120,       
        },
        // {
        //   title: 'Caja',
        //   dataIndex: 'destination_checkout_name',  
        //   width: 120,  
        //   render: (text, record)=> <RenderCheckout text={text} record={record}/>,
        //   className:'cursor-pointer text-right cell_border_left  background_cell_credit',
        //   filters: filters('destination_checkout_name', dataApi),
        //   onFilter: (value, record) => typeof value === 'string' && record.destination_checkout_id == value,
        //   filterSearch: true,      
        // },
        {
          title: 'Saldo caja',
          dataIndex: 'balance_destination_checkout',
          width: 110,
          className:'cursor-pointer  text-right cell_border_left  background_cell_credit',
          render: (text, record)=> <RenderCheckout text={text} record={record}/>,
        },
        {
          title: 'Débito',
          dataIndex: 'debit',
          width: 130, 
          className:'cursor-pointer cell_border_left cell_debit text-right background_cell_debit',
          filters: filters('debit', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.debit == value,
          filterSearch: true, 
          render:(text: string, record: DataType)=><RenderAmount text={text} record={record} type={'debit'}/>,
        },      
        {
          title: 'Cliente', //cuenta origen
          dataIndex: 'origin_account_id',          
          render: (text, record)=><RenderCustomerOrigin text={text} record={record}/>,
          className:'cursor-pointer text-right background_cell_debit cell_border_left ',  
          ellipsis: true,  
          width: 170, 
          filters: filters('origin_account_id', dataApi),
          onFilter: (value, record) => typeof value === 'string' && record.origin_account_id == value,
          filterSearch: true,
        },  
        {
          title: 'Sucursal - Caja',
          dataIndex: 'origin_checkout_branch',
          render: (text, record)=>{  
            let txt = record.origin_checkout_branch !== '' && record.origin_checkout_name !== '' 
              ? record.origin_checkout_branch + ' - ' + record.origin_checkout_name
              : ''
            return <RenderCheckout text={txt} record={record}/>},
          className: ' text-right cell_border_left background_cell_debit',
          width: 120,       
        },
        // {
        //   title: 'Caja',
        //   dataIndex: 'origin_checkout_name',   
        //   width: 120,   
        //   className:'cursor-pointer  text-right background_cell_debit cell_border_left ',
        //   render: (text, record)=> <RenderCheckout text={text} record={record}/>,
        //   filters: filters('origin_checkout_name', dataApi),
        //   onFilter: (value, record) => typeof value === 'string' && record.origin_checkout_id == value,
        //   filterSearch: true,
        // }, 
        {
          title: 'Saldo caja',
          dataIndex: 'balance_origin_checkout',
          width: 110,
          className:'cursor-pointer text-right background_cell_debit cell_border_left ',
          render: (text, record)=> <RenderCheckout text={text} record={record}/>,
        },
      
        {
          title: 'Descripción',
          dataIndex: 'description',
          className: 'cursor-pointer cell_border_left',
          ellipsis: true,
          width: 310,
        },    
        {
          title: 'Eliminar' ,
          dataIndex: 'id',     
          render:  handleRenderer,
          className: 'text-center',
          width: 65,     
          fixed: 'right'
        }, 
      ];
      const updatedColumns = newColumns.map((item, index) => {
          let key = index + 1
          if(item.title =='Eliminar'){
            setDeletedkey(key.toString()) 
          }
          if(item.title == 'Aprobado'){
            setApprovedkey(key.toString()) 
          }
          return({
            ...item,
            key: key, // Nueva propiedad "key"
          })}
        );
      setColumnsOperationSections(updatedColumns)    
    }
  } 
  

  const [currencySelected, setCurrencySelected] = useState<{
    id: number,
    name: string,
    sign: string
  }>()

  const {
    dataTable, 
    setDataTable,
    mutate, 
    setMutate, 
    sectionsFilters, 
    setSectionsFilters,
  } = useContext(OperationsContext)

  const mandatory = [
    {
      required: true,
      message:'Este campo es obligatorio'
    }
  ] 

  const errorCustomers = 'NO HAY CLIENTES CREADOS PARA HACER UNA BÚSQUEDA POR CLIENTE. CREE UNO EN: ADMINISTRACIÓN => CLIENTES.'
  const errorMoney = 'NO HAY MONEDAS CREADAS PARA HACER UNA BÚSQUEDA POR MONEDA. CREE UNA EN: ADMINISTRACIÓN => MOEDAS.'
  
  useEffect(()=>{
    if(dataTable.length == 0){
      handleSearch(true, false)    
    }
    if(sectionsFilters){
      form_operations_filters.setFieldsValue({
        operation_type: sectionsFilters.operation_type || undefined,
        customer: sectionsFilters.customer || undefined,
        currency: sectionsFilters.currency || undefined,
        branch: sectionsFilters.branch || undefined,
        checkout: sectionsFilters.chekout
      })
      if(sectionsFilters.branch){
        setSelectedBranch(sectionsFilters.branch.key)
      }
    }
  },[])  

  useEffect(()=>{
    if(mutate){
      handleSearch(false, false)  
      setMutate(false)
    }
  },[mutate])

  const rowData = async (data: Transaction[]) => {
    if (!data) return; 
    const promises = data.map(async (transaction) => {
      let created_by: string | undefined
      let updated_by: string | undefined
      let created_at: string = moment(transaction.created_at).format('DD-MM-YYYY') || ''
      let updated_at: string = moment(transaction.updated_at).format('DD-MM-YYYY') || ''      
      let created_at_hr: string | undefined = moment(transaction.created_at).format('HH:mm:ss') || undefined
      let updated_at_hr: string | undefined = moment(transaction.updated_at).format('HH:mm:ss') || undefined

      if(transaction.created_by){
        created_by = transaction.created_by.first_name + ' ' +  transaction.created_by.last_name
      }
      if(transaction.updated_by){
          updated_by = transaction.updated_by.first_name + ' ' +  transaction.updated_by.last_name
      }
      return {
        approved: transaction.approved ? 'true' : 'false',
        associated_operation: transaction.related_operation_user_id?.toString() || undefined,
        balance_destination_checkout: transaction.destination_checkout_balance?.toString() || '',
        balance_origin_checkout: transaction.origin_checkout_balance?.toString() || '',
        can_be_approved: transaction.can_be_approved ? 'true' : ' false',
        commission: transaction.commission || '',
        created_at: created_at,
        credit: transaction.credit?.toString() || '',
        credit_number: transaction.credit?.toString() || '',
        currency_quote_buy_price: transaction.currency_quote_buy_price || undefined,
        currency_quote_sell_price: transaction.currency_quote_sell_price || undefined,
        currency_sale: transaction.currency_sale ? 'true' : 'false',
        customer_first_name: transaction.customer?.first_name || '',
        customer_id: transaction.customer?.id?.toString() || '',
        customer_last_name: transaction.customer?.last_name || '',
        date: updated_at ? updated_at : created_at,
        hours: updated_at_hr ? updated_at_hr : created_at_hr,
        debit: transaction.debit?.toString() || '',
        debit_number: transaction.debit?.toString() || '',
        deleted: transaction.deleted ? 'true' : 'false',
        deposit_operation_approved: transaction.deposit_operation_approved ? 'true' : ' false',
        description: transaction.description || '',
        destination_account_balance: transaction.destination_account_balance?.toString() || '',
        destination_account_currency_id: transaction.destination_account?.currency?.id?.toString() || '',
        destination_account_currency_name: transaction.destination_account?.currency?.name || '',
        destination_account_currency_sign: transaction.destination_account?.currency?.sign || '',
        destination_account_customer_id: transaction.destination_account?.customer?.id?.toString() || '',
        destination_account_customer_last_name: transaction.destination_account?.customer?.last_name || '',
        destination_account_customer_name: transaction.destination_account?.customer?.first_name || '',
        destination_account_id: transaction.destination_account?.id?.toString() || '',
        destination_checkout_balance: transaction.destination_checkout?.balance?.toString() || '',
        destination_checkout_currency_id: transaction.destination_checkout?.currency?.id?.toString() || '',
        destination_checkout_currency_name: transaction.destination_checkout?.currency?.name || '',
        destination_checkout_currency_sign: transaction.destination_checkout?.currency?.sign || '',
        destination_checkout_id: transaction.destination_checkout?.id?.toString() || '',
        destination_checkout_name: transaction.destination_checkout?.name || '',
        destination_checkout_branch: transaction.destination_checkout?.branch?.name || '',
        destination_checkout_branch_id: transaction.destination_checkout?.branch?.id?.toString() || '',
        extraction_operation_approved: transaction.extraction_operation_approved ? 'true' : ' false',
        full_name: transaction.full_name || '',
        id: transaction.id?.toString(),
        is_deleting_operation: transaction.is_deleting_operation ? 'true' : 'false',
        is_sale: transaction.currency_sale === true ? 'true' : transaction.currency_sale === false ? 'false' : undefined,
        main_currency_sign: transaction.main_currency?.sign || '',
        operation_id: transaction.operation_id?.toString() || '',
        operation_type_id: transaction.type.id?.toString(),
        operation_type_name: transaction.type.name,
        origin_account_balance: transaction.origin_account_balance?.toString() || '',
        origin_account_currency_id: transaction.origin_account?.currency?.id?.toString() || '',
        origin_account_currency_name: transaction.origin_account?.currency?.name || '',
        origin_account_currency_sign: transaction.origin_account?.currency?.sign || '',
        origin_account_customer_id: transaction.origin_account?.customer?.id?.toString() || '',
        origin_account_customer_last_name: transaction.origin_account?.customer?.last_name || '',
        origin_account_customer_name: transaction.origin_account?.customer?.first_name || '',
        origin_account_id: transaction.origin_account?.id?.toString() || '',
        origin_checkout_balance: transaction.origin_checkout?.balance?.toString() || '',
        origin_checkout_currency_id: transaction.origin_checkout?.currency.id?.toString() || '',
        origin_checkout_currency_name: transaction.origin_checkout?.currency?.name || '',
        origin_checkout_currency_sign: transaction.origin_checkout?.currency?.sign || '',
        origin_checkout_id: transaction.origin_checkout?.id?.toString() || '',
        origin_checkout_name: transaction.origin_checkout?.name || '',
        origin_checkout_branch: transaction.origin_checkout?.branch?.name ||  '',
        origin_checkout_branch_id: transaction.origin_checkout?.branch.id?.toString() || undefined,
        related_operation_id: transaction.related_operation_id?.toString() || undefined, 
        secondary_currency_id: transaction.secondary_currency?.id?.toString() || undefined,
        secondary_currency_name: transaction.secondary_currency?.name || undefined,
        secondary_currency_sign: transaction.secondary_currency?.sign || undefined,
        supplier_commission: transaction.supplier_commission,
        updated_at:updated_at,
        usdt_quote_percentage: transaction.usdt_quote_percentage ? transaction.usdt_quote_percentage : undefined,
        usdt_sale: transaction.usdt_sale === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined, 
        windows_operation:  transaction.windows_operation === true ? 'true' : transaction.windows_operation === false ? 'false' : undefined,
        currency_quote: transaction.currency_quote_buy_price?.toString() || 
          transaction.currency_quote_sell_price?.toString() ||
          undefined,
        porcentage_currency_quote: transaction.usdt_quote_percentage ? transaction.usdt_quote_percentage + '%' : undefined,
        approved_date: transaction.approved_date ? moment(transaction.approved_date).format('DD-MM-YYYY') : undefined,
        transfer_costs: transaction.transfer_costs || undefined,   
        client_pays_costs: transaction.client_pays_costs === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined,   
        created_by: transaction.created_by?.first_name ||' ' + ' ' + transaction.created_by?.last_name ||' ',
        updated_by: transaction.updated_by?.first_name ||' '+ ' ' + transaction.updated_by?.last_name ||' ',
        show_user: updated_by 
            ? updated_by 
            : created_by
                ? created_by
                : '',                  
      };
    });
  
    try {
      const filterData = await Promise.all(promises);
      setDataTable([...filterData]);
    } catch (error) {
      console.error('Error processing row data:', error);
    }
  };

  const callbackEliminate = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
  }

   
  const ModalConfirmDelete = React.memo( () => {
    return(
        <Modal
            className='movements_section_icon'
            title="Eliminar"
            open={showConfirmDelete}
            onOk={async()=>{
                if(rowSelect && rowSelect.id){
                  const data = await deleteOperation(callbackEliminate, +rowSelect.id)
                  if(data){
                    handleSearch(false, false)
                  }
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar operación N° {rowSelect?.operation_id} ?</p>
        </Modal>
    )
  })   

  const onClickRow = (record: DataType) =>{         
    if(record &&  record.approved == 'false'){
      setRowSelect(record);
    }  
  }    
    
  const handleSearch = async(loadingTable: boolean, loadingRefresh: boolean) =>{
      if(loadingTable){
        setLoadingTableData(true)
      } 
      if(loadingRefresh){
        setLoadingBtn({
          search: false,
          refresh: true
        })
      }

      let formData:{
        date: {$d: string}[],
        operation_type: any,
        customer: ValuesForm, 
        currency: ValuesForm,
        branch: ValuesForm,
        checkout: ValuesForm        
      } = form_operations_filters.getFieldsValue()

      let start_date = formData.date && formData.date[0] ? moment(formData.date[0].$d).format('YYYY-MM-DD') : undefined
      let end_date = formData.date && formData.date[1] ? moment(formData.date[1].$d).format('YYYY-MM-DD') : undefined
      let currency =  formData.currency || undefined  
      let customer =  formData.customer || undefined
      let operation_type = formData.operation_type || undefined 
      let branch = formData.branch || undefined
      let checkout = formData.checkout || undefined
      
      let values ={
        customer_id: customer && customer.key,
        currency_id: currency && currency.key,  
        start_date: start_date,
        end_date: end_date,
        operation_type: operation_type && operation_type.key,
        branch_id: branch && branch.key,
        checkout_id: checkout && checkout.key
      }

     // checkAccountBalance()
      const data = await getOperationAmount(
        callbackEliminate,
        values
      )  
      if(data){
        makeColumns(data)
        rowData(data)
        setDataApi(data)
        setSectionsFilters({
          date: {
            to: formData.date && formData.date[0] || undefined,
            from: formData.date && formData.date[1] || undefined
          },
          currency:  formData.currency || undefined,          
          customer: formData.customer || undefined,
          operation_type: formData.operation_type || undefined,
          branch:   formData.branch || undefined,
          chekout: formData.checkout || undefined
        })
      } 
      if(loadingRefresh){
        setLoadingBtn({
          search: false,
          refresh: false
        })
      }  
  }


  return( 
    <>
      <ModalConfirmDelete/>
      { showForms.usdt &&
        <USDT
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }       
      { showForms.usdt_windows &&
        <USDTWindows
          form_buy_and_sell={form_usdt_windows}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      { showForms.windows &&
        <Windows
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }
      { showForms.transferer && 
        <TransfersBetweenAccounts
          form={form_transferers}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }    
      { showForms.buy_and_sell &&
        <PucrchaseSaleCurrencyForm
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }   
      { showForms.transfers_between_cashs && 
        <TransfersBetweenBoxes
          form={transfers_between_cashs}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      {
        showForms.transferer_to_suppliers &&
          <TransfersSuppliers
            form={transferer_to_suppliers}
            rowSelect={rowSelect}
            setRowSelect={setRowSelect}
          />
      }
      { showForms.funds_movements && 
        <FundsMovementForm        
        form={form_funds_movements}
        rowSelect={rowSelect}
        setRowSelect={setRowSelect}
        />
      }    
      <div className='movements_section movements_section_container hover_row'>  
          <Row
            className='global_title_section'  
          >
            <Col
              style={{
                display: 'flex',
                alignItems: ' center',
              }}
            >
              <h3> <DatabaseOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>TRANSACCIONES</h3> 
            </Col>
          </Row>                                          
          <Row 
            className='global_button_box'
            style={{
              display:'flex',
              justifyContent: 'space-between',
              alignItems:'center'
            }}
          >              
            <Col >
              <Form
                layout='inline'
                onFinish={()=>handleSearch(true, false)}
                id='form_operations_filters' 
                form={form_operations_filters}
              >
                <Form.Item
                    name='date'
                >
                  <RangePicker 
                    style={{width: 240}}
                    placeholder={['Desde', 'Hasta']} 
                  />
                </Form.Item>
                {/* <Form.Item
                    name='operation_type'
                >
                  <Select                           
                    labelInValue  
                    loading={!operation_type }
                    placeholder={!operation_type ? 'Cargando campos...' : 'Operación'}      
                    style={{minWidth: '100px'}}    
                    showSearch 
                    allowClear                                                 
                  >
                      {operation_type && operation_type.map((item) => (
                          <Option value={item.name} key={item.id} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item> */}
                <Form.Item
                  name='branch'
                >
                  <Select                           
                      labelInValue  
                      loading={!currencies}
                      placeholder={!currencies ? 'Cargando...' : 'Sucursal'}                       
                      style={{minWidth: '100px'}}
                      //onChange={(e, value) => onChangeBranch(e, value)}
                      showSearch
                      allowClear 
                      onChange={(e:{key: number})=>{
                        if(e?.key){
                          setSelectedBranch(e.key)
                          form_operations_filters.setFieldsValue({
                            checkout: undefined
                          })
                        }else{
                          setSelectedBranch(undefined)
                        }
                        
                      }}
                  >
                      {branches && branches && branches.map((item) => (
                          item.id !== 0 &&
                          <Option value={item.name} key={item.id} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>   
                <Form.Item
                  name='checkout'
                >
                  <Select                           
                      labelInValue  
                      loading={!currencies}
                      placeholder={!currencies ? 'Cargando ...' : 'Caja'}                       
                      style={{minWidth: '150px'}}
                      //onChange={(e, value) => onChangeBranch(e, value)}
                      showSearch
                      allowClear 
                  >
                    {
                      selectedBranch
                        ? cashs && cashs && cashs
                            .filter((item)=>item.branch.id == selectedBranch || item.branch.id == 0)
                            .map((item) => (
                            <Option value={item.name} key={item.id} >
                              {item.name}
                            </Option>
                          ))
                        : cashs && cashs && cashs.map((item) => (
                            <Option value={item.branch.name + ' - ' + item.name} key={item.id} >
                              {item.branch.name + ' - ' + item.name}
                            </Option>
                          ))
                    }
                  </Select>
                </Form.Item>              
                <Form.Item
                    name='customer'
                >
                  <Select                           
                    labelInValue  
                    loading={!clients}
                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                    style={{minWidth: '150px'}}   
                    showSearch  
                    allowClear                                                 
                  >
                      {clients && clients.map((item) => (
                          <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                            {item.first_name + ' ' + item.last_name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                {/* <Form.Item
                    name='currency'
                >
                  <Select                           
                      labelInValue  
                      loading={!currencies}
                      placeholder={!currencies ? 'Cargando monedas...' : 'Moneda'}                       
                      style={{minWidth: '100px'}}
                      onChange={(e, value) => onChangeCurrency(e, value)}
                      showSearch
                      allowClear 
                  >
                      {currencies && currencies && currencies.map((item) => (
                          <Option value={item.name} key={item.id} sign={item.sign} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item> */}
                <Form.Item>
                  <Button
                  type='primary'
                  className='movements_section_btn'
                  htmlType='submit'
                  loading={loadingBtn.search}
                  >
                    Buscar
                  </Button>
                </Form.Item>
                <Button 
                    type='primary'
                    className='allCheckout_section_btn'
                    onClick={()=>handleSearch(false, true)}
                    //disabled={!branchSelected}
                    style={{marginRight: 10}}
                    loading={loadingBtn.refresh}
                >
                    Refresh                        
                </Button>   
                <Tooltip
                  title= 'Seleccione uno o varios filtros para hacer una búsqueda.'
                  className='cursor-pointer'
                >
                  <ExclamationCircleOutlined style={{color: 'orange'}}/>
                </Tooltip>
              </Form>                               
            </Col>  
                          
          </Row>
          {((clients && clients.length === 0) || (currencies && currencies.length === 0)) &&
            <Row
              style={{ 
                paddingLeft: '30px',
                paddingBottom: '10px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {clients && clients.length === 0 && (
                <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                  <WarningOutlined 
                    style={{marginRight: '10px', marginTop: '10px', color: '#71e3ff'}} 
                  /> 
                  {errorCustomers}
                </span>
              )}
              {
                currencies && currencies.length === 0 && (
                <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                  <WarningOutlined 
                    style={{marginRight: '10px', marginTop: '10px', color: '#71e3ff'}} 
                  /> 
                  {errorMoney}
                </span>
              )}
            </Row>
          }
          <Row 
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '85%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            { loadingTableData && <SpinerHorizontal/>}
            { !loadingTableData && dataTable
                ?
                  <Table 
                    style={{
                        width: '100%',
                        marginTop: '1%'
                    }}                    
                    columns={columnsOperationSections} 
                    dataSource={dataTable}  
                    scroll={{ y: adjustedTableHeigthScroll, x: 800 }}                                   
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: false,                                      
                    }}
                    rowClassName={(record)=>{  return record.deleted === 'true' || record.is_deleting_operation === 'true' ? 'cell_deleted custom-table' : 'custom-table'}}
                    onRow={(record, ) => ({
                      onClick: (event) => {
                        // Verifica condiciones principales y selecciona celdas de "Aprobado" y "Eliminar" una sola vez
                        const isApprovable = record.approved === 'false' && record.can_be_approved === 'true' && record.deleted === 'false' && record.is_deleting_operation === 'false';
                        const noRelatedOperation = !record.related_operation_id || record.related_operation_id === 'false';
                        
                        if (isApprovable && noRelatedOperation) {
                          const approvedCell = event.currentTarget.querySelector(`td:nth-child(${approvedKey})`); 
                          const deleteCell = event.currentTarget.querySelector(`td:nth-child(${deletedKey})`);
                      
                          // Verifica si el clic ocurrió en las celdas "Aprobado" o "Eliminar"
                          if ((approvedCell && approvedCell.contains(event.target)) || (deleteCell && deleteCell.contains(event.target))) {
                            return;
                          }
                      
                          // Llamada al manejador de clic
                          onClickRow(record);
                      
                          // Variables comunes para los estados de formulario
                          const isAccountValid = record.origin_account_customer_id !== '0' && record.destination_account_customer_id !== '0';
                          const isRelated = isAccountValid && !record.related_operation_id;
                          let is_usdt_windos = record.windows_operation == 'true' && record.operation_type_id ==='9'
                          let formStates
                          // Define el estado del formulario
                          if(record.operation_type_id){
                            formStates = {
                              deposit_extraction: ['1', '2', '3', '4'].includes(record.operation_type_id) && isRelated,
                              transfers_between_cashs: record.operation_type_id === '5' && isAccountValid,
                              transferer: record.operation_type_id === '6' && isAccountValid,
                              buy_and_sell: record.operation_type_id === '7' && isRelated && record.origin_account_customer_id !== '0',
                              windows: record.operation_type_id === '7' && record.origin_account_customer_id === '0',
                              usdt: record.operation_type_id === '9' && !is_usdt_windos && isRelated,
                              usdt_windows: is_usdt_windos,
                              transferer_to_suppliers: ['10', '11'].includes(record.operation_type_id) && isAccountValid, 
                              funds_movements: ['14', '15'].includes(record.operation_type_id), 
                            };
                          }
                        
                      
                          setShowForms(formStates);
                        }
                      }
                    })}
                  /> 
                : 
                 <>
                 </>
            }                             
          </Row>             
      </div>
    </>
  )
};

export default OperationSections;

