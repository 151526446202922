import React, {  useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps,   Col,   Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { FundsMovementFormProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { useGetCashDesk } from "../../../Services/useGetCashDesk";
import { useGetOperationType } from "../../../Services/useGetOperationType";
import { useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { useGetCheckoutBank } from "../../../Services/useGetCheckoutBank";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { useGetBranch } from "../../../Services/useGetBranch";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}


interface inputsForms{
    date: {$d:string}
    operation_type: FilterValues,
    client: FilterValues,
    cash: FilterValues,
    amount: number,
    currency: FilterValues
    commission: string,
    description: string,
    transfer_costs: string,
    client_pays_costs: boolean  
}

type OperationData = {
    description: string;
    type_id: number;
    user_id: number;
    approved: boolean;    
    debit?: number;
    credit?: number;
    destination_checkout_id?: number,    
    origin_checkout_id?: number;
};

export const FundsMovementForm: React.FC<FundsMovementFormProps> =({
    form, 
    rowSelect,
    setRowSelect
})=>{
    
    const { data: cashs } = useGetCashDesk()
    const { userData } =useContext(UserContext)
    const { data: cashs_bank } = useGetCheckoutBank()
    const { data: operation_type } = useGetOperationType()
    const { collapsedFilsters, setCollapsedFilters } = useContext(VariablesContext)
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { createOperation, editOperation, getOperation } = useOperations()
    const { getAccountsCurrencyAndCustomer } = useAccounts()  
    const { Option } = Select;
    const {data: branches } = useGetBranch()
 
    const filtered = operation_type && operation_type.filter(item => [14,15].includes(item.id));
    const [ loadingBtn, setLoadingBtn ] = useState(false)
    const [ isTransferer, setIsTransferer ] = useState(false)
    const [ aproved, setAproved ] = useState(false)
    const [ clientPayCost, setClientPayCost ] = useState(false)
    const [ operationBank, setOperationBank ] = useState<boolean>(false)
    const [ operationType, setOperationType ] = useState<number | undefined>()
    const [ spanError, setSpanError ] = useState<string>()
    const [ client, setClient ] = useState<number | undefined>()
    const [ currency, setCurrency ] = useState<{id: number, sign: string} | undefined>()
    const [ balance, setBalance ] = useState<ResponseAccounts>()
    const [amountInput, setAmountInput] = useState<number>()
    const [branchSelected, setBranchSelected] = useState<number | undefined>()    

    const handleCancel =()=>{
        setLoadingBtn(false)
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(setRowSelect){
            setRowSelect(undefined)  
        }        
    }

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    const uploadForm = async() =>{
        if(rowSelect && rowSelect.id){
            const response = await getOperation(+rowSelect.id)
            if(response){
                let cash_data
                let amount_data
    
                // if(response?.type.id == 3 || response?.type.id == 4){
                //     setOperationBank(true)                 
                // }
                
                switch(response.type.id){
                    case 14: // Depósito fondo                                           
                        let cash_destination = cashs.filter((cash)=> cash.id == response.destination_checkout.id)[0]
    
                        cash_data ={
                            label: response.destination_checkout.name,
                            value: response.destination_checkout.name,
                            key: response.destination_checkout.id,
                        }                    
                        amount_data = response.credit                        
                        setCurrency({id: cash_destination.currency.id, sign: cash_destination.currency.sign})                    
                        setAmountInput(response.credit)
                        if(response.destination_checkout.branch.id){
                            setBranchSelected(response.destination_checkout.branch.id)
                        }
                        form.setFieldsValue({
                            branch_cahs_extraction:{
                                value: response.destination_checkout.branch.id || undefined,
                                label: response.destination_checkout.branch.name || undefined,
                                key: response.destination_checkout.branch.name || undefined,
                            },
                        })
                        break;
                    case 2: // Extracción de fondos
                        let cash_origin = cashs.filter((cash) => cash.id == response.origin_checkout?.id)[0];
                        cash_data = {
                            label: response.origin_checkout?.name ,
                            value: response.origin_checkout?.name,
                            key: response.origin_checkout?.id,
                        };                    
                        amount_data = response.debit;

                        setCurrency({ id: cash_origin.currency.id, sign: cash_origin.currency.sign });
                        setAmountInput(response.debit);
                        if(response.origin_checkout.branch.id){
                            setBranchSelected(response.origin_checkout?.branch?.id)
                        }
                        form.setFieldsValue({
                            branch_cahs_extraction:{
                                value: response.origin_checkout?.branch?.id || undefined,
                                label: response.origin_checkout?.branch?.name || undefined,
                                key: response.origin_checkout?.branch?.name || undefined,
                            },
                        })
                        
                        break;
                    default:
                        break
                }                              
                if(cash_data ){
                    form.setFieldsValue({
                        operation_type: {
                            label: response.type.name,
                            value: response.type.name,
                            key: response.type.id
                        },
                        cash: cash_data,
                        amount: amount_data,
                        description: response.description,                    
                    });  
                } 
                setAmountInput(amount_data)                  
            }            
        }else{
            form.resetFields(undefined) 
        }
        
        
    }

    useEffect(() => {
        uploadForm()        
    }, []);

    const handleSave = async (e: inputsForms)=>{
        setLoadingBtn(true)
        let operationData: OperationData | undefined;  
        if(userData){
            let operation_type_id = Number(e.operation_type.key)
            switch (operation_type_id) {
                case 14: // Depósito efectivo
                    operationData = {                                     
                        description: e.description,
                        type_id: Number(e.operation_type.key),
                        destination_checkout_id: Number(e.cash.key),
                        user_id: userData?.id,
                        credit: Number(e.amount),  
                        approved: aproved,                                                                            
                    };
                    break;
                case 15: // Extracción efectivo
                    operationData = {
                        description: e.description,
                        type_id: Number(e.operation_type.key),                
                        origin_checkout_id: Number(e.cash.key),
                        user_id: userData?.id,
                        debit: Number(e.amount),   
                        approved: aproved,                   
                    };
                    break;
                default:
                    handleCancel()
                    break;
            }
        }             
        if(operationData){
            let newData
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id!, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }  
            // if(newData){
            //     if (
            //         showingAccount && 
            //         showingAccount.user_id == newData.customer.id 
            //     ){
            //         setMutate(true)
            //     }
            //     mutateOperationsSections(true)
            // }                                 
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)            
        }                
    }    

    const changeTransferer = (e: FilterValues ) =>{
        if(Number(e.key) == 3 || Number(e.key) == 4){
            setOperationBank(true)
        }else{
            setOperationBank(false)
        }
        setSpanError(undefined)
        setOperationType(e.key)        
        setAproved(false)
        setAmountInput(undefined)
        form.resetFields([
            'branch_cahs_extraction', 
            'cash', 
            'sell_currency', 
            'amount',  
            'aproved', 
            'amount', 
            'description'
        ]);      
    }

    const onChangeCash = async (e: FilterValues, values: any) =>{
        if(spanError){
            setSpanError(undefined)
        }
        if(values?.currency){
            setCurrency({id: values.currency, sign: values.sign})            
        }        
    }
 
    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    }; 

    return(
        <div>
            <Modal
                title= 'Depósito / Extracción de fondos '
                open={showForms.funds_movements }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >                   
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'                
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue
                            placeholder="Seleccione el tipo de movimiento"   
                            onChange={changeTransferer}                         
                        >
                            {filtered && filtered.map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>                                                              
                    <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Sucursal'
                                name='branch_cahs_extraction'
                                rules={mandatory}
                            >
                                <Select
                                    style={{minWidth: 120}}
                                    labelInValue  
                                    onChange={(e)=>{
                                        setBranchSelected(+e.key)
                                        form.setFieldValue('extraction_cash', undefined)
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        item.id !== 0 &&
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Caja'
                                name='cash'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}                                
                            >                           
                                <Select                           
                                    labelInValue  
                                    loading={!cashs }
                                    placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}}  
                                    showSearch    
                                    onChange={onChangeCash}                          
                                >
                                    {cashs && cashs
                                        .filter((item) => item.branch.id === branchSelected || item.branch.id == 0)
                                        .map((item) => (
                                        <Option value={item.name} key={item.id}  currency={item.currency.id} sign={item.currency.sign}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select>                                                                               
                        </Form.Item>  
                        </Col>
                    </Row>                                                                                              
                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            min={0}
                            addonBefore={currency ? currency.sign : ' ¤ '} 
                            placeholder="Monto"
                            type="number"
                        />
                    </Form.Item>                   
                    <Form.Item
                        name='aproved'
                    >
                        <Checkbox
                            checked={aproved}
                            onChange={onChangeAproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>                                        
                    <span style={{color: 'red'}}>{spanError && spanError} </span>                              
                </Form>
            </Modal>
        </div>
    )
}

export default FundsMovementForm;
