import { axiosBackEndInstance } from "../Axios";
import { END_POINT_ACCOUNT_BY_CURRENCY, END_POINT_ACCOUNT_BY_CUSTOMER_AND_CURRENCY } from "../Axios/ENDPOINTS";
import { useErrorHandling } from "../hooks/useErrorHandling";
import { Accounts } from "./useGetAllAccount";


export interface AccountsProps {
  id: number,
  name: string
}

export interface ResponseAccounts{
  description: string,
  currency_id: number,
  type_id: 1,
  is_active: boolean,
  balance: number,
  id: number,
  future_balance: number
}

export const useAccounts= () => {

  const {errorHandling} = useErrorHandling()

    const getAccountsCurrency = async (currency_id: number, setLoadingDestinationAccount?: Function): Promise<Accounts[] | undefined> =>{
      try{
        const data = await axiosBackEndInstance.get(
          `${END_POINT_ACCOUNT_BY_CURRENCY}/${currency_id}`
        )
        if(data.status === 200 || data.status === 201){
          if(setLoadingDestinationAccount){
            setLoadingDestinationAccount(false)
          }          
          return data.data        
        }
      }catch(newError){
        if(setLoadingDestinationAccount){
          setLoadingDestinationAccount(false)
        }
        let status = errorHandling(newError).request.status
        console.log('status: ' + status + ' ' + newError);
      }
    }
  
    const getAccountsCurrencyAndCustomer = async ( customer_id: number, currency_id: number, callback?: Function): Promise<ResponseAccounts | undefined> =>{
      try{
        const data = await axiosBackEndInstance.get(
          `${END_POINT_ACCOUNT_BY_CUSTOMER_AND_CURRENCY}/${customer_id}/${currency_id}`
        )
        if(data.status === 200 || data.status === 201){
          if(callback){
            callback(false)
          }
          return data.data        
        }
      }catch(newError){
        if(callback){
          callback(false)
        }
        let status = errorHandling(newError).request.status
        console.log('status: ' + status + ' ' + newError);
      }
    }

    return {
      
      getAccountsCurrency,
      getAccountsCurrencyAndCustomer
    };
  };
  