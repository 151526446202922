import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_USER_ACCOUNT_GET_ALL } from '../Axios/ENDPOINTS';
import { CurrencyProps } from "./useGetCurrency";
import { Customer } from "./useGetMovements";
import { TypeAccountProps } from "./useGetAccountType";



export interface Accounts {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  balance: number;
  estimated_balance: number;
  currency: CurrencyProps;
  customer: Customer;
  type: TypeAccountProps; 
  future_balance?: number 
}



export const useGetAllAccount = () => {
    
    const { data, error, mutate }:{
      data: Accounts[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([ `${END_POINT_USER_ACCOUNT_GET_ALL}` ])   
  
    return {
      data,
      mutate,
      error
    };
};