import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    DeleteOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { CustomerSectionsProps } from './types';
import { useCashDesk } from '../../../Services/useCashDesk';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { AllClientsProps, useGetAllClients } from '../../../Services/useGetAllClients';
import { useCustomers } from '../../../Services/useCustomers';
import CustomerForm from '../../Forms/CustomerForm';
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';


export interface DataType {
  index: number,
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string, 
  is_supplier: string,
  is_customer: string 
}

const CustomerSections: React.FC<CustomerSectionsProps> = () => {

    const {data: dataApi} = useGetAllClients()    
    const {deleteCustomers} = useCustomers()
    const {getCheckout} = useCashDesk()
    
    const {adjustedTableHeigthScroll} = useContext(StylesContext) 

    const [form_customer] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    

    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
    },[dataApi])  

  const rowData = (data? : AllClientsProps[]) =>{
      let filterData: DataType[]= []
      let list: AllClientsProps[] = data ? data : dataApi
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id.toString(),
            first_name: data?.first_name || '',
            last_name: data?.last_name || '',
            email: data?.email || '',
            phone_number: data?.phone_number?.toString() || '',
            is_supplier: data?.is_supplier ? 'true': 'false',
            is_customer: data?.is_customer ? 'true' : 'false',
          })
        })                  
      }       
      setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)
        setOpenForm(false)
        setShowConfirmDelete(false)
    }

    const handleRenderer = (text: string, record: DataType) =>{
      return (
          <div className='cash_desk_action_action_render'>
              <DeleteOutlined 
                  onClick={()=>{
                      setRowSelect(record)
                      setShowConfirmDelete(true)
                  }}
                  style={{marginLeft:'30px', color: '#37bde0'}} /> 
          </div>  
      )      
    }
    const ModalConfirmDelete = () => {
      return(
          <Modal
              title="Eliminar"
              open={showConfirmDelete}
              onOk={()=>{
                  if(rowSelect && rowSelect.id){
                      deleteCustomers(callback, +rowSelect.id)
                  }}}
              onCancel={()=>setShowConfirmDelete(false)}
              okText="Eliminar"
              cancelText="Cancelar"
          >
              <p>¿Esta seguro que desea eliminar el cliente {rowSelect?.first_name} {rowSelect?.first_name}?</p>
          </Modal>
      )
  }

    const filters = (
      type: 'name' | 'is_supplier' | 'is_customer'
    ) => {
      let textAndValue: { text: string, value: string }[] = [];
      let uniqueValues = new Set();

      dataApi?.forEach((item) => {
        let code = ''
        let value = ''
        switch(type){
          case 'name':
              code = item.first_name + ' ' + item.last_name
              value =  item.id.toString()
              break;
          case 'is_supplier':
              code = item.is_supplier ? 'Es proveedor' : 'No es proveedor';
              value = item.is_supplier ? 'true' : 'false';
              break; 
          case 'is_customer':
              code = item.is_supplier ? 'Es cliente' : 'No es cliente';
              value = item.is_supplier ? 'true' : 'false';
              break;                          
          default:
              code ='';
              value = ''
              break               
        }
      
        if (code && !uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: value
            });
        }

    });
  textAndValue.sort((a, b) => {
    if (!isNaN(Number(a.text)) && !isNaN(Number(b.text))) {
        return Number(a.text) - Number(b.text); // Para números
    } else {
        return a.text.localeCompare(b.text); // Para strings
    }
  });
  return textAndValue;
}
  
    
  const columns: ColumnsType<DataType> = [
    {
      title: 'Nombre Cliente',
      dataIndex: 'name',
      key: '1',
      className:'cursor-pointer',
      render: (text: string, reccord: DataType) => <div>{reccord.first_name} {reccord.last_name}</div>,
      filters: filters('name'),
      onFilter: (value, record) => typeof value === 'string' && record.id == value,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: '2',
      className:'cursor-pointer',
      sorter: (a, b) => a.email.localeCompare(b.email),
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Celular',
      dataIndex: 'phone_number',
      key: '3',
      className:'cursor-pointer',
     // filters: filters('branch_name'),
      //onFilter: (value, record) => typeof value === 'string' && record.branch_name.includes(value),
      //filterSearch: true,
      onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Es proveedor',
      dataIndex: 'is_supplier',
      filters: filters('is_supplier'),
      onFilter: (value, record) => typeof value === 'string' && record.is_supplier == value,
      filterSearch: true,
      render:(text, record)=> record.is_supplier == 'true' 
        ? <CheckOutlined style={{color: 'greenyellow', paddingLeft: '10px'}} /> 
        : <CloseOutlined style={{color: 'red', paddingLeft: '10px'}}/>  ,
      key: '4',
      className:'cursor-pointer',
      sorter: (a, b) => a.is_supplier.localeCompare(b.is_supplier),
       onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Es cliente',
      dataIndex: 'is_customer',
      filters: filters('is_customer'),
      onFilter: (value, record) => typeof value === 'string' && record.is_customer == value,
      filterSearch: true,
      render:(text, record)=> record.is_customer == 'true' 
        ? <CheckOutlined style={{color: 'greenyellow', paddingLeft: '10px'}} /> 
        : <CloseOutlined style={{color: 'red', paddingLeft: '10px'}}/>  ,
      key: '5',
      className:'cursor-pointer',
      sorter: (a, b) => a.is_customer.localeCompare(b.is_customer),
       onCell: (record, rowIndex) => {
          return {
            onClick: () => {
              onClickRow(record)
            }
          };
        }
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render:  handleRenderer,
      key: '6',
      width: 110,      
    }, 
  ];
    
    const onClickRow = (record: DataType) =>{         
      setRowSelect(record)
        setOpenForm(true) 
    }  
    

    return( 
        <>
          <ModalConfirmDelete/>
          { openForm && <CustomerForm
              openForm={openForm} 
              callback={callback}
              rowSelect={rowSelect}
              form_customer={form_customer}
          /> }     
          <div className='cash_desk_section cash_desk_section_container'>                                  
              <Row
                  className='global_title_section'  
              >
                  <h3>CLIENTES</h3>                
              </Row>
              <Row 
                className='global_button_box'
              >
                  <Button
                    type='primary'
                    className='movements_section_btn'
                    onClick={()=>{setOpenForm(true)}}
                    >
                      Nuevo cliente
                  </Button>                  
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '95%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '100%',
                          marginTop: '1%'
                      }}
                          columns={columns} 
                          dataSource={dataTable}  
                          scroll={{ y: adjustedTableHeigthScroll}}                                   
                          pagination={{
                              pageSize: 50,
                              showSizeChanger: false,                                      
                          }}
                      />    
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default CustomerSections;