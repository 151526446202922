import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { 
    END_POINT_GENERAL_BALANCE 
} from '../Axios/ENDPOINTS';
import { CurrencyProps } from "./useGetCurrency";
import { Accounts } from "./useGetAllAccount";
import { CheckoutBalance } from "./useCashDesk";
import { BranchProps } from "./useGetBranch";

type Description = {
    error: number;
    description: string;
} | null;
  
  
export interface Discrimination {
    currency: CurrencyProps;
    balance: number;
    future_balance: number;
    checkout_balance: number;
    future_checkout_balance: number;
    account_balance: number;
    future_account_balance: number;
};

export interface ResponseGeneralBalance {
    description: Description;
    general_balance: string;
    future_general_balance: string;
    checkout_balance: string;
    future_checkout_balance: string;
    account_balance: string;
    future_account_balance: string;
    discriminations: Discrimination[];
    checkouts: CheckoutBalance[];
    accounts: Accounts[];
  };


export const useGetGeneralBalance = () => {
    
    const { data, error, mutate }:{
      data: ResponseGeneralBalance, 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([ `${END_POINT_GENERAL_BALANCE}` ])   
  
    return {
      data,
      mutate,
      error
    };
};