import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form, Tooltip } from 'antd';
import {
    DeleteOutlined, 
    ExclamationCircleOutlined, 
    WarningOutlined,
    DollarOutlined,
    WalletOutlined      
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps, ValuesForm } from './types';
import { Transaction } from '../../../Services/useGetMovements';
import { useGetCashDesk } from '../../../Services/useGetCashDesk';
import { CheckoutBalance, useCashDesk } from '../../../Services/useCashDesk';
import { useOperations } from '../../../Services/useOperations';
import moment from 'moment';
import { RenderApproved } from '../../RenderApproved';
import { useGetCurrency } from '../../../Services/useGetCurrency';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { VariablesContext } from '../../../Contexts/UserContext/VariablesContext';
import { MovementCashContext } from '../../../Contexts/UserContext/MovementCashContext';
import { DataType } from '../../../Contexts/UserContext/MovementAcountContext';
import { useGetBranch } from '../../../Services/useGetBranch';
import RenderOperationType from '../../RenderOperationType';
import RenderNumOperations from '../../RenderNumOperations/RenderNumOperations';
import DepositExtractionForm from '../../Forms/DepositExtractionForm';
import PucrchaseSaleCurrencyForm from '../../Forms/PucrchaseSaleCurrencyForm';
import USDT from '../../Forms/USDT';
import TransfersSuppliers from '../../Forms/TransfersSuppliers';
import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import FundsMovementForm from '../../Forms/FundsMovementForm';

const RenderCustomer = React.memo(({
  text,
  record
}: {text: string, record: DataType}) =>{
  let customer: string = text ? text : ''
  let colorTxt='#ADADAD'

  if(record.deleted === 'true' || record.is_deleting_operation === 'true'){
      colorTxt = 'rgb(114, 114, 114)'
  }else if(record.full_name){
      customer = record.full_name
      colorTxt = '#FFC107'
  }

    switch(record.operation_type_id){
      case '1':
      case '3':
        if(record.destination_account_customer_id){
          customer =  record.destination_account_customer_name + ' ' + record.destination_account_customer_last_name
        }       
        break;
      case '2':
      case '3':
        if(record.origin_account_customer_id){
          customer =  record.origin_account_customer_name + ' ' + record.origin_account_customer_last_name  
        }
        break;
      case '5':
        customer = ''
        break;
      case '7':
        if(record.windows_operation == 'true' && record.full_name){
          customer = record.full_name
        }
    } 
    if(record.full_name){
      customer = record.full_name
    }   

  return (
    <>
        {
          record.full_name
            ?
              <Tooltip
                title='Ventanilla '
              >
                <span
                  style={{
                      display: 'inline-block',
                      width: '100%', 
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color:colorTxt ,
                  }}
              >
                  {customer}  <QuestionCircleOutlined/>
              </span>
              </Tooltip>
            
            : <span>{customer}</span>
        }
      </> 
    )
})



const MovementsCash: React.FC<UsersSectionProps> = () => {
  
  const {data: cashs} = useGetCashDesk()
  const {getOperationAmount, deleteOperation} = useOperations()
  const { data: currencies } = useGetCurrency()
  const { Option } = Select;
  const {getCheckoutBalance} = useCashDesk()
  const {adjustedTableHeigthScroll} = useContext(StylesContext) 
  const {setShowForms, showForms} = useContext(VariablesContext)
  const {data: branches } = useGetBranch()

  const {
    dataTable, 
    setDataTable,
    mutate, 
    setMutate,
    showingCash, 
    setShowingCash,
    balance, 
    setBalance,
    branchSelected,
    setBranchSelected
  } = useContext(MovementCashContext)

  const [form_selected_cash] = useForm()
  const [form_buy_and_sell] = useForm()
  const [form_deposit_extraction] = useForm()
  const [form_usdt] = useForm()
  const [form_transferer_to_suppliers] = useForm()
  const [form_funds_movements] = useForm()
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState<{
      search: boolean,
      refresh: boolean
  }>({
      search: false,
      refresh: false
  })
  

  const error = 'NO HAY CAJAS CREADAS PARA HACER UNA BÚSQUEDA. CREE UNA EN: ADMINISTRACIÓN => CAJAS.'
  
  useEffect(()=>{
    if(mutate){
      handleSearch(false, false)
      setMutate(false)
    }
  },[mutate])

  useEffect(()=>{
    if(showingCash){
      form_selected_cash.setFieldsValue({
          cash:{
            value: showingCash.name,
            label: showingCash.name,
            key: showingCash.id
          }
      })
    }
  },[])

  const handleSearch = async(loading: boolean, loadingRefresh: boolean ) =>{
    if(loading){
      setLoadingTableData(true)
    }     
    if(loadingRefresh){
      setLoadingBtn({
        search: false,
        refresh: true
      })
    } 
    let formData:{cash: ValuesForm}  = form_selected_cash.getFieldsValue()    
    let cash =  formData.cash      
    if(formData && formData.cash && formData.cash.key){
      checkCheckoutBalance()
      let values ={
        customer_id: undefined,
        currency_id: undefined, 
        checkout_id: +cash.key,   
        start_date: undefined,
        end_date: undefined,
        operation_type: undefined,
        branch_id: undefined
      }
      const data = await getOperationAmount(setLoadingTableData, values)
      if(data){      
        setShowingCash({
          name: cash.label,
          id: cash.key
        })
        rowData(data)
      }   
    }  
    if(loadingRefresh){
      setLoadingBtn({
        search: false,
        refresh: false
      })
    } 
  }

  const checkCheckoutBalance =  React.useCallback(async()=>{             
    let formData:{cash: ValuesForm}  = form_selected_cash.getFieldsValue()  
    if(formData && formData.cash && formData.cash.key){
      let checkout_id = formData.cash.key  
      const data = await getCheckoutBalance(checkout_id)
      if(data && data){
          setBalance((prevData: CheckoutBalance) => {
              if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                  return data;
              }
              return prevData; // No actualizar si son iguales
          });
      }
    }         
  },[form_selected_cash, getCheckoutBalance])

  const selectedAccountDetails = async (list: Transaction) => {
    let sign = list.origin_checkout?.currency?.sign 
          ?? list.destination_checkout?.currency?.sign 
          ?? ''
    let accountData = {
      debit: '',
      credit: '',
      balance: '',
      sign: ''
    };
    //busco la coincidencia con la caja buscada y utilizo esos datos para cargar debit, credit y balance de la tabla
     
    let formData:{cash: ValuesForm}  = form_selected_cash.getFieldsValue() 

    if (formData && formData.cash && formData.cash.key) {
      let checkout_id = formData.cash.key 
      if (list.origin_checkout && list.origin_checkout.id == checkout_id) {
        accountData = {
          debit:  ` ${new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.debit)}`,
          credit: '',
          balance:  ` ${!list.approved ? '' : new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.origin_checkout_balance)}`,
          sign: list.origin_checkout.currency.sign
        };
      }
      if (list.destination_checkout && list.destination_checkout.id == checkout_id) {
        accountData = {
          debit: '',
          credit:   `${new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.credit)}`,
          balance:  ` ${!list.approved  ? '' : new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.destination_checkout_balance)}`,
          sign: list.destination_checkout.currency.sign
        };
      }
    }
    return accountData;
  };


  

  const rowData =async (data : Transaction[]) =>{
    if (!data) return; 
    const promises = data.map(async (transaction) => {
      const accountData = await selectedAccountDetails(transaction);
      let created_by: string | undefined
      let updated_by: string | undefined
      let created_at: string | undefined = moment(transaction.created_at).format('DD-MM-YYYY') || undefined
      let updated_at: string | undefined = moment(transaction.updated_at).format('DD-MM-YYYY')
      let created_at_hr: string | undefined = moment(transaction.created_at).format('HH:mm:ss') || undefined
      let updated_at_hr: string | undefined = moment(transaction.updated_at).format('HH:mm:ss') || undefined

      if(transaction.created_by){
        created_by = transaction.created_by.first_name + ' ' +  transaction.created_by.last_name
      }
      if(transaction.updated_by){
          updated_by = transaction.updated_by.first_name + ' ' +  transaction.updated_by.last_name
      }

      return {
        approved: transaction.approved ? 'true' : 'false',
        approval_date: transaction.approved_date ? moment(transaction.approved_date).format('DD-MM-YYYY') : '',
        associated_operation: transaction.related_operation_user_id?.toString() || undefined,
        balance: accountData.balance,
        balance_destination_checkout: transaction.destination_checkout_balance?.toString() || '',
        balance_origin_checkout: transaction.origin_checkout_balance?.toString() || '',
        can_be_approved: transaction.can_be_approved ? 'true' : ' false',
        commission: transaction.commission,
        create_date: moment(transaction.created_at).format('DD-MM-YYYY') || moment(transaction.created_at).format('DD-MM-YYYY  HH:mm:ss'),
        created_at: moment(transaction.updated_at).format('DD-MM-YYYY') || moment(transaction.created_at).format('DD-MM-YYYY  HH:mm:ss'),
        credit: accountData.credit,
        credit_number: transaction.credit?.toString() || '',
        currency_quote_buy_price: transaction.currency_quote_buy_price || undefined,
        currency_quote_sell_price: transaction.currency_quote_sell_price || undefined,
        currency_sale: transaction.currency_sale ? 'true' : 'false',
        customer_first_name: transaction.customer?.first_name || '',
        customer_id: transaction.customer?.id?.toString() || '',
        customer_last_name: transaction.customer?.last_name || '',
        date: updated_at ? updated_at : created_at,
        debit: accountData.debit,
        debit_number: transaction.debit?.toString() || '',
        deleted: transaction.deleted ? 'true' : 'false',
        deposit_operation_approved: transaction.deposit_operation_approved ? 'true' : ' false',
        description: transaction.description || '',
        destination_account_balance: transaction.destination_account_balance?.toString() || '',
        destination_account_currency_id: transaction.destination_account?.currency?.id?.toString() || '',
        destination_account_currency_name: transaction.destination_account?.currency?.name || '',
        destination_account_currency_sign: transaction.destination_account?.currency?.sign || '',
        destination_account_customer_id: transaction.destination_account?.customer?.id?.toString() || '',
        destination_account_customer_last_name: transaction.destination_account?.customer?.last_name || '',
        destination_account_customer_name: transaction.destination_account?.customer?.first_name || '',
        destination_account_id: transaction.destination_account?.id?.toString() || '',
        destination_checkout_balance: transaction.destination_checkout?.balance?.toString() || '',
        destination_checkout_currency_id: transaction.destination_checkout?.currency.id.toString() || '',
        destination_checkout_currency_name: transaction.destination_checkout?.currency?.name || '',
        destination_checkout_currency_sign: transaction.destination_checkout?.currency?.sign || '',
        destination_checkout_id: transaction.destination_checkout?.id?.toString() || '',
        destination_checkout_name: transaction.destination_checkout?.name || '',
        destination_checkout_branch: transaction.destination_checkout?.branch?.name || '',
        destination_checkout_branch_id: transaction.destination_checkout?.branch?.id?.toString() || '',
        extraction_operation_approved: transaction.extraction_operation_approved ? 'true' : ' false',
        full_name: transaction.full_name || '',        
        hours: updated_at_hr ? updated_at_hr : created_at_hr,
        id: transaction.id?.toString(),
        is_deleting_operation: transaction.is_deleting_operation ? 'true' : 'false',
        is_sale: transaction.currency_sale === true ? 'true' : transaction.currency_sale === false ? 'false' : undefined,
        operation_id: transaction.operation_id?.toString() || '',
        operation_type_id: transaction.type.id?.toString(),
        operation_type_name: transaction.type.name,
        origin_account_balance: transaction.origin_account_balance?.toString() || '',
        origin_account_currency_id: transaction.origin_account?.currency?.id?.toString() || '',
        origin_account_currency_name: transaction.origin_account?.currency?.name || '',
        origin_account_currency_sign: transaction.origin_account?.currency?.sign || '',
        origin_account_customer_id: transaction.origin_account?.customer?.id?.toString() || '',
        origin_account_customer_last_name: transaction.origin_account?.customer?.last_name || '',
        origin_account_customer_name: transaction.origin_account?.customer?.first_name || '',
        origin_account_id: transaction.origin_account?.id?.toString() || '',
        origin_checkout_balance: transaction.origin_checkout?.balance?.toString() || '',
        origin_checkout_currency_id: transaction.origin_checkout?.currency.id?.toString() || '',
        origin_checkout_currency_name: transaction.origin_checkout?.currency?.name || '',
        origin_checkout_currency_sign: transaction.origin_checkout?.currency?.sign || '',
        origin_checkout_id: transaction.origin_checkout?.id?.toString() || '',
        origin_checkout_name: transaction.origin_checkout?.name || '',
        origin_checkout_branch: transaction.origin_checkout?.branch?.name ||  '',
        origin_checkout_branch_id: transaction.origin_checkout?.branch.id?.toString() || undefined,
        related_operation_id: transaction.related_operation_id?.toString() || undefined,
        secondary_currency_id: transaction.secondary_currency?.id?.toString() || undefined,
        secondary_currency_name: transaction.secondary_currency?.name || undefined,
        secondary_currency_sign: transaction.secondary_currency?.sign || undefined,
        supplier_commission: transaction.supplier_commission,
        updated_at: moment(transaction.updated_at).format('DD-MM-YYYY') || moment(transaction.updated_at).format('DD-MM-YYYY  HH:mm:ss'),
        usdt_quote_percentage: transaction.usdt_quote_percentage ? transaction.usdt_quote_percentage : undefined,
        usdt_sale: transaction.usdt_sale === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined, 
        windows_operation:  transaction.windows_operation === true ? 'true' : transaction.windows_operation === false ? 'false' : undefined,
        currency_quote: transaction.currency_quote_buy_price?.toString() || 
                        transaction.currency_quote_sell_price?.toString() ||
                        undefined,
        porcentage_currency_quote: transaction.usdt_quote_percentage ? transaction.usdt_quote_percentage + '%' : undefined,
        currency: accountData.sign,  
        transfer_costs: transaction.transfer_costs || undefined,   
        client_pays_costs: transaction.client_pays_costs === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined,   
        created_by: transaction.created_by?.first_name ||' ' + ' ' + transaction.created_by?.last_name ||' ',
        updated_by: transaction.updated_by?.first_name ||' '+ ' ' + transaction.updated_by?.last_name ||' ',
        show_user: updated_by 
            ? updated_by 
            : created_by
                ? created_by
                : '', 
      };
    });
  
    try {
      const filterData = await Promise.all(promises);
      // Filtrar elementos nulos
      const validData = filterData.filter((item) => item !== null && item !== undefined);
      setDataTable([...validData]);
    } catch (error) {
      console.error('Error processing row data:', error);
    }
  }

  const callbackEliminate = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
  }

  const handleRenderer = (text: string, record: DataType) =>{
    return (
        <div className='currency_action_action_render'>
          {
            record.deleted == 'true' 
            || record.is_deleting_operation == 'true' 
           // || (record.full_name && record.operation_id! !== '7')
            ||  record.related_operation_id 
              ? <></>
              :  <DeleteOutlined 
              onClick={()=>{
                  setRowSelect(record)
                  setShowConfirmDelete(true)
              }}
              style={{marginLeft:'30px', color: '#37bde0'}} /> 
          }           
        </div>  
    )      
  }
    
  const ModalConfirmDelete = () => {
    return(
        <Modal
            title="Eliminar"
            open={showConfirmDelete}
            onOk={async()=>{
              if(rowSelect && rowSelect.id){
                const data = await deleteOperation(callbackEliminate, +rowSelect.id)
                if(data){
                  handleSearch(false, false)
                }
              }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar operación N° {rowSelect?.operation_id}?</p>
        </Modal>
    )
  }

  const handleRender = (text: string, record: DataType) => {
    const baseClass =
      record.deleted == 'true'
        ? 'class_deleted'
        : record.is_deleting_operation == 'true'
        ? 'class_is_deleting_operation'
        : 'class_false';
      
    return (
      <span 
        title={text}
        className={`${baseClass} hover_row`}>
        {text}
      </span>
    );
  };

    const handleRenderBalance = (text: string, record: DataType) => {
      const baseClass =
        record.deleted == 'true'
          ? 'class_deleted'
          : record.is_deleting_operation == 'true'
            ? 'class_is_deleting_operation'
            : 'class_balance';
    
      return (
        <div 
          className={`${baseClass} hover_row`}
        >
          { text && text !== '' && currencies.filter(x => x.id === balance?.currency.id)[0]?.sign }
          {' '}
          {text}
        </div>
      );
    };
  
  const RenderDebitorCredit = (text: string, record: DataType, type?: 'debit' | 'credit') => {
    const baseClass =
      record.deleted == 'true'
        ? 'class_deleted'
        : record.is_deleting_operation == 'true'
        ? 'class_is_deleting_operation'
        : '';
  
    const textColorClass = baseClass
      ? baseClass // Si hay un baseClass, usarlo
      : type === 'debit'
      ? 'text-orange'
      : type === 'credit'
      ? 'text-green'
      : 'class_false'; // Si no hay baseClass, usar el color por tipo
  
    return (
      <div className={`${textColorClass} hover_row`}>
        {text && currencies.filter(x => x.id === balance?.currency.id)[0]?.sign }
        {' '}
        {text}
      </div>
    );
  };

  const filters = (type: 'operation_id' | 'operation_type_name' | 'date') => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();
  
    let hasEliminated = false; // Bandera para saber si hay eliminados
  
    dataTable?.forEach((item) => {
      let code: string;
      let value: string;
      
      switch (type) {
        case 'operation_id':
          if (item.operation_id! === 'NC') {
            code = 'Nota de crédito';
            value = 'NC';
          } else if (item.operation_id!!.includes('(E)')) {
            // Si contiene (E), es una operación eliminada
            if (!hasEliminated) {
              // Agregar opción general para "Eliminadas" si no está ya agregada
              textAndValue.push({
                text: 'Eliminadas',
                value: 'Eliminadas'
              });
              hasEliminated = true;
            }
            // Añadir la operación eliminada con su número, como "25 (E)"
            code = item.operation_id!;
            value = item.operation_id!;
          } else {
            code = item.operation_id!;
            value = item.operation_id!;
          }
          break;
        case 'operation_type_name':
          code = item.operation_type_name!;
          value = item.operation_type_name!;
          break;
        case 'date':
          code = item.date!;
          value = item.date!;
          break;
      }
  
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        textAndValue.push({
          text: code,
          value: value
        });
      }
    });
  
    // Insertar "Activos" al principio solo cuando el tipo sea 'operation_id'
    if (type === 'operation_id') {
      textAndValue.unshift({
        text: 'Activos',
        value: 'Activos'
      });
    }
  
    return textAndValue;
  };
  
  const columns: ColumnsType<DataType> = [
    {
      title: 'N° ',
      dataIndex: 'operation_id',
      width: 70,
      key: '1',
      render: (text, record)=> <RenderNumOperations text={text} record={record}/>, 
      className:'cursor-pointer',
      filters: filters('operation_id'),
      onFilter: (value, record) => {
        if (value === 'Eliminadas') {
          // Filtrar todas las operaciones que contengan "(E)" en el operation_id
          return record.operation_id!.includes('(E)');
        }
        if (value === 'Activos') {
          // Filtrar todos los que NO sean eliminados ni NC
          return !record.operation_id!.includes('(E)') && record.operation_id! !== 'NC';
        }
        // Filtrar por un número específico de operación eliminada o normal
        return typeof value === 'string' && (record.operation_id! === value || record.operation_id!.includes(value));
      },
      filterSearch: true,  
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      width: 100,
      className:'cursor-pointer text-center', 
      filters: filters('date'),
      onFilter: (value, record) => typeof value === 'string' && record.date == value,
      filterSearch: true, 
      key: '2',      
    },
    {
      title: 'Hora',
      key: '3',  
      className:'cursor-pointer text-center',
      dataIndex: 'hours',
      width: 100,      
    },
    {
      title: 'Tipo de operación',
      dataIndex: 'operation_type_name',
      filters: filters('operation_type_name'),
      onFilter: (value, record) => typeof value === 'string' && record.operation_type_name == value,
      filterSearch: true,
      render: (text, record)=>{return<RenderOperationType text={text} record={record}/>},
      //render: renderOperationType,
      width: 150,
      key: '4',
      className:'cursor-pointer',
    },  
    {
      title: 'Cliente',
      dataIndex: 'customer_name',
      render: (text: string, record: DataType)=> <RenderCustomer text={text} record={record}/>,
      width: 150,
      key: '5'
    },  
    {
      title: 'Descripción',
      dataIndex: 'description',
      width: 170,
      render: handleRender,      
      key: '6',
      className:'cursor-pointer',  
      ellipsis: true,
     
    },
    {
      title: 'Débito',
      dataIndex: 'debit',
      key: '7',
      width: 120,
      render: (text, render)=>RenderDebitorCredit(text, render, 'debit'),
      className:'cursor-pointer',
    },
    {
      title: 'Crédito',
      dataIndex: 'credit',
      key: '8',      
      width: 120,
      render: (text, render)=>RenderDebitorCredit(text, render, 'credit'),      
      className:'cursor-pointer',
    },    
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: '9',   
      width: 140,
      render: handleRenderBalance,
      className:'cursor-pointer  key-7',
    },
    {
      title: 'Aprobado',
      dataIndex: 'approved',
      key: '10',
      width: 100,
      render: (text: string, record: DataType)=> <RenderApproved text={text} record={record} setDataTable={setDataTable} dataTable={dataTable} callback ={handleSearch}/>,
      className:'cursor-pointer key-8',      
     
    },
    {
      title: 'Eliminar',
      dataIndex: 'id',
      key: '11',        
      render:  handleRenderer,
      width: 100,      
    }, 
  ];

   const onClickRow = (record: DataType) =>{         
      if(record &&  record.approved == 'false'){
        setRowSelect(record);
      }  
    }      

  return( 
    <>
      { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }    
      { showForms.buy_and_sell &&
        <PucrchaseSaleCurrencyForm
          form_buy_and_sell={form_buy_and_sell}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      { showForms.usdt &&
        <USDT
          form_buy_and_sell={form_usdt}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      {
        showForms.transferer_to_suppliers &&
          <TransfersSuppliers
            form={form_transferer_to_suppliers}
            rowSelect={rowSelect}
            setRowSelect={setRowSelect}
          />
      } 
      { showForms.funds_movements && 
        <FundsMovementForm        
          form={form_funds_movements}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      <ModalConfirmDelete/>
      <div className='movements_cash_section movements_cash_section_container'>                                  
          <Row
            className='global_title_section'  
          >
            <Col              
              style={{
                display: 'flex',
                alignItems: ' center'
              }}
            >
              <h3> <WalletOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>MOVIMIENTOS POR CAJA</h3> 
              {
                showingCash && 
                  <h3
                    style={{ margin: '7px 0px 0px 10px', padding: '0px', color: '#bdbdbd'}}
                  >/ {showingCash?.name}</h3> 
              }
            </Col>
            <Col
              style={{display: 'flex'}}
            >
              <div 
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: balance && balance.balance < 0 ? 'red' : '#37bee042', 
                  borderBottom:`2px solid ${balance && balance.balance < 0 ? '#ff7c7c' : '#37bde0'}`, 
                  paddingLeft: '15px', 
                  marginRight: '30px',
                  borderRadius: '5px',
                  height: '32px',
                  width: 'auto',
                  minWidth: '210px'
                }}>
                <h3 
                  style={{
                    margin: '0px',
                    
                  }}
                >
                  Saldo: {currencies && balance && currencies.filter(x => x.id === balance?.currency.id)[0]?.sign }  {' '}                                    
                </h3>
                <h3
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}
                >
                  {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance) : ''}
                </h3>
              </div>  
              <div 
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: balance && balance.future_balance > balance.balance ? '#ffff00a1': balance && balance.future_balance < balance?.balance ? '#ff00009a' :'#80808098', 
                  borderBottom: `2px solid ${balance && balance.future_balance > balance.balance ? 'yellow' : balance && balance.future_balance < balance.balance ? 'red' : 'gray'}`,
                  paddingLeft: '15px', 
                  marginRight: '30px',
                  borderRadius: '5px',
                  height: '32px',
                  width: 'auto',
                  minWidth: '220px'
                }}>
                <h3 
                  style={{
                    margin: '0px',
                    
                  }}
                >
                  S. Futuro: {currencies && balance && currencies.filter(x => x.id === balance?.currency.id)[0]?.sign }  {' '}                                    
                </h3>
                <h3
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}
                >
                  {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.future_balance) : ''}
                </h3>
              </div> 
            </Col>                           
          </Row>
          <Row 
            className='global_button_box'
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Col>
              <Form
                layout='inline'
                form={form_selected_cash}
                onFinish={()=>handleSearch(false, false)}
              >
                <Form.Item
                  name='branches'
                  initialValue={   branchSelected ? {
                     value: branches.find((x)=>x.id == branchSelected)?.name,
                      label: branches.find((x)=>x.id == branchSelected)?.name,
                      key:   branches.find((x)=>x.id == branchSelected)?.id
                  } : undefined}
                  rules={[
                    {
                        required: true,
                        message:'Este campo es obligatorio'
                    }
                  ]}
                >
                    <Select
                      style={{minWidth: '200px'}}
                      labelInValue  
                      onChange={(e)=>{
                        setBranchSelected(+e.key)
                        setShowingCash(null);
                        form_selected_cash.setFieldsValue({ cash: undefined })
                      }}
                      placeholder= 'Sucursal'

                    >
                        {branches && branches.map((item) => (
                            item.id !== 0 &&
                            <Option value={item.name} key={item.id}>
                                {item.name}
                            </Option>
                        ))} 
                    </Select>  
                </Form.Item>
                <Form.Item
                    name='cash'
                    rules={[
                      {
                          required: true,
                          message:'Este campo es obligatorio'
                      }
                    ]}
                    initialValue={showingCash ? {
                      value: showingCash.name,
                      label: showingCash.name,
                      key: showingCash.id
                    } : undefined} 
                >
                  <Select                           
                    labelInValue 
                    loading={!cashs}
                    placeholder={!cashs ? 'Cargando cajas...' : 'Caja'}      
                    style={{minWidth: '200px'}}  
                    showSearch                                                
                  >
                    {cashs && cashs
                        .filter((item) => item.branch.id === branchSelected || item.branch.id == 0)
                        .map((item) => (
                          <Option value={item.name} key={item.id}>
                            {item.name}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>  
                <Form.Item>
                  <Button
                    type='primary'
                    className='movements_cash_section_btn'
                    htmlType='submit'
                  >
                    Buscar
                  </Button>
                </Form.Item>
                <Button 
                  type='primary'
                  className='allCheckout_section_btn'
                  onClick={()=>handleSearch(false, true)}
                  //disabled={!branchSelected}
                  style={{marginRight: 10}}
                  loading={loadingBtn.refresh}
                >
                    Refresh                        
                </Button> 
              </Form>                 
            </Col>              
            {
              cashs && cashs.length == 0 && 
                <div style={{marginTop: '10px', display: 'flex', flexDirection: 'column'}}>
                  <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                    <WarningOutlined style={{marginRight: '10px', color: '#71e3ff '}}/>{error}
                  </span>
                </div>   
            }                          
          </Row>
                  
          <Row 
            className='hover_row'
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '85%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            { loadingTableData && <SpinerHorizontal/>}
            { !loadingTableData && balance  && 
              <Table 
                style={{
                    width: '100%',
                    marginTop: '1%'
                }}
                columns={columns} 
                scroll={{ y: adjustedTableHeigthScroll, x: 1000 }}
                dataSource={dataTable}                                 
                pagination={{
                    pageSize: 50,
                    showSizeChanger: false,                                      
                }}
                onRow={(record, ) => ({
                  onClick: (event) => {
                    // Verifica condiciones principales y selecciona celdas de "Aprobado" y "Eliminar" una sola vez
                    const isApprovable = record.approved === 'false' && record.can_be_approved === 'true' && record.deleted === 'false' && record.is_deleting_operation === 'false';
                    const noRelatedOperation = !record.related_operation_id || record.related_operation_id === 'false';
                    
                    if (isApprovable && noRelatedOperation) {
                      const approvedCell = event.currentTarget.querySelector('td:nth-child(10)');
                      const deleteCell = event.currentTarget.querySelector('td:nth-child(11)');
                  
                      // Verifica si el clic ocurrió en las celdas "Aprobado" o "Eliminar"
                      if ((approvedCell && approvedCell.contains(event.target)) || (deleteCell && deleteCell.contains(event.target))) {
                        return;
                      }
                  
                      // Llamada al manejador de clic
                      onClickRow(record);
                  
                      // Variables comunes para los estados de formulario
                      const isAccountValid = record.origin_account_customer_id !== '0' && record.destination_account_customer_id !== '0';
                      const isRelated = isAccountValid && !record.related_operation_id;
                  
                      // Define el estado del formulario basado en las condiciones simplificadas
                      const formStates = {
                        deposit_extraction: ['1', '2', '3', '4'].includes(record.operation_type_id!) && isRelated,
                        transfers_between_cashs: record.operation_type_id === '5' && isAccountValid,
                        transferer: record.operation_type_id === '6' && isAccountValid,
                        buy_and_sell: record.operation_type_id === '7' && isRelated && record.origin_account_customer_id !== '0' && record.windows_operation == 'false',
                        windows: record.operation_type_id === '7' && record.origin_account_customer_id === '0',
                        usdt: record.operation_type_id === '9' && isAccountValid,
                        transferer_to_suppliers: ['10', '11'].includes(record.operation_type_id!) && isAccountValid, 
                        funds_movements: ['14', '15'].includes(record.operation_type_id!), 
                      };
                  
                      setShowForms(formStates);
                    }
                  },
                })}
              />    
            } 
          </Row>             
      </div>
    </>
  )
};

export default MovementsCash;