
import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {   Row, Table } from 'antd';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { BalanceByCurrencyProps } from './types';
import { Discrimination } from '../../../Services/useGetGeneralsBalance';


export interface DataType {
  index: string,
  currency_name: string,
  currency_sign: string,
  currency_id: string,
  balance: string,
  checkout_balance: string,
  future_checkout_balance: string,
  account_balance: string,
  future_account_balance: string
}

const HandleRender = React.memo<{
  text: string, 
  record: DataType,   
  color: string
}>(({text, record, color}) => {
  let set_color = color;
  const cleanedText = text.replace(/\./g, '').replace(',', '.');
  if (cleanedText && !isNaN(Number(cleanedText)) && Number(cleanedText) < 0) {
    set_color = 'red';
  }
  return(
    <span 
      style={{
        color: set_color,
        fontWeight: 600
      }}
    >
      {text && text !== '' && record.currency_sign} {text}
    </span>
  )
})

const BalanceByCurrency: React.FC<BalanceByCurrencyProps> = ({
  dataApi
}) => {

    const {adjustedTableHeigthScroll} = useContext(StylesContext)    

  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])  

  useEffect(()=>{
    setLoadingTableData(true)
    if(dataApi){
      rowData(dataApi) 
      setLoadingTableData(false)
    }
  },[dataApi])  

  const rowData = (data : Discrimination[]) =>{
      let filterData: DataType[]= []
      let list: Discrimination[] = data ? data : dataApi
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index.toString(),
            currency_name: data.currency.name,
            currency_sign: data.currency.sign,
            currency_id: data.currency.id.toString(),
            balance: Number(data.balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
            checkout_balance: Number(data.checkout_balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
            future_checkout_balance:  Number(data.future_checkout_balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
            account_balance: Number(data.account_balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })|| '',
            future_account_balance: Number(data.future_account_balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''
          })
        })        
          
      }       
      setDataTable(filterData)
    }
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Moneda',
            dataIndex: 'currency_name',
            width: 100
        },      
        {
          title: 'Balance total / Fondos netos',
          dataIndex: 'balance',          
          render: (text, record)=><HandleRender text={text} record={record} color='#20B2AA'/>,
        },
        {
          title: 'Balance cajas / bancos',
          dataIndex: 'checkout_balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#20B2AA'/>,
        },
        {
          title: 'En caja / banco futuro',
          dataIndex: 'future_checkout_balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#FF69B4'/>,
        },
        {
          title: 'Balance Ctas Ctes',
          dataIndex: 'account_balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#20B2AA'/>,
        },
        {
          title: 'Ctas Ctes Futuro',
          dataIndex: 'future_account_balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#FF69B4'/>,
        },

    ];
    
    const onClickRow = (record: DataType) =>{         

    } 


    return( 
      <>                                                         
        <Row 
          className='balance_by_currency'
          style={{
              display:'flex',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              paddingRight: 16,                
          }}
        >
          {
            loadingTableData
            ? <SpinerHorizontal/> 
            : <Table 
                style={{
                    width: '100%',
                }}
                columns={columns} 
                dataSource={dataTable}  
                pagination={false} 
            />    
          } 
        </Row>  
      </>                
)};

export default BalanceByCurrency
