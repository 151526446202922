import React from 'react';
import {  Row, Tooltip } from 'antd';
import {
    CloseOutlined,
    UndoOutlined,
    QuestionCircleOutlined
  } from '@ant-design/icons';

import { DataType } from '../../Contexts/UserContext/MovementAcountContext';

export const RenderDestinationAccountCustomer = React.memo((value: {text: any, record: DataType})=>{
  let balance: number | undefined 
  let sign = ''
  switch(value.record.operation_type_id){
    case '1':
    case '2':
    case '3':
    case '4':
      if(value.record.destination_account_balance && value.record.destination_account_balance !== ''){
        balance = +value.record.destination_account_balance
        sign = value.record.destination_account_currency_sign || ''
      }
     
      break;
    default:
      if(value.record.destination_account_balance && value.record.destination_account_balance !== ''){
        balance = +value.record.destination_account_balance
        sign = value.record.destination_account_currency_sign || ''
      }
      break
  }
  return (
    <span>
      {sign}
      {' '}
      {balance && balance !== 0 && Number(balance).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    </span>
  )
})

export const RenderOriginAccountCustomer = React.memo((value: {text: any, record: DataType})=>{
  let balance: number | undefined 
  let sign = ''
  switch(value.record.operation_type_id){
    case '1':
    case '2':
    case '3':
    case '4':
      if(value.record.origin_account_balance && value.record.origin_account_balance !== ''){
        balance = +value.record.origin_account_balance
        sign = value.record.origin_account_currency_sign || ''
      }
     
      break;
    default:
      if(value.record.origin_checkout_id && value.record.origin_checkout_id == ''){
        
      }else if(value.record.origin_account_balance && value.record.origin_account_balance !== ''){
        balance = +value.record.origin_account_balance
        sign = value.record.origin_account_currency_sign || ''
      }
      break
  }
  return (
    <span>
      {sign}
      {' '}
      {balance && balance !== 0 && Number(balance).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    </span>
  )
})

export const RenderNumOperations = React.memo((value: {text: any, record: DataType})=>{
  return(
      <Row
          justify={
            value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
              ? 'space-between'
              : 'start'
          }
          style={{fontSize: 12}}
      >
          <span >{value.text}</span>            
          {value.record.deleted === 'true' && 
              <Tooltip
                  title='Eliminado'
              >
                  <CloseOutlined style={{color: 'red'}}/>
              </Tooltip>                
          }
           {value.record.is_deleting_operation === 'true' && 
              <Tooltip
                  title='Nota de crédito'
              >
                  <UndoOutlined style={{color: 'red'}}/>  
              </Tooltip>                
          }
      </Row>
      
  )
})

export const RenderCustomerDestination = React.memo((value:{text: any, record: DataType})=>{ 
  let data =  value.record
  let customer = ''
 
  if(data.destination_account_customer_id && data.destination_account_customer_id !== '0'){
    customer = data.destination_account_customer_name + ' ' + data.destination_account_customer_last_name  +' -  [  ' + data.destination_account_currency_sign + ' ]  '
  }
  return(
    <Tooltip title={customer}>
      <span>{customer}</span> 
    </Tooltip>                 
  )
  }
)

export const RenderCustomerOrigin = React.memo((value:{text: any, record: DataType})=>{ 
  let data =  value.record
  let customer = ''
  if(data.origin_account_customer_id && data.origin_account_customer_id !== '0'){
    customer = data.origin_account_customer_name + ' ' + data.origin_account_customer_last_name + ' -  [  ' + data.origin_account_currency_sign + ' ]  '
  }
  return(
    <Tooltip title={customer}>
      <span>{customer}</span> 
    </Tooltip>               
  )
  }
)


export const formatted = (value: string)=>{

  const number = parseFloat(value);

  let data =  new Intl.NumberFormat('es-AR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return  data
}

export const RenderAmount = React.memo(
  ({ text, record, type }: { text: string; record: DataType; type: 'credit' | 'debit' }) => {
    let sign = '';

    if (type === 'credit') {
      if(record.destination_checkout_currency_sign !==''){ 
        sign = record.destination_checkout_currency_sign || ''
      }else{
        sign = record.destination_account_currency_sign || ''
      }
    } else if (type === 'debit') {
      if(record.origin_checkout_currency_sign){
        sign = record.origin_checkout_currency_sign || ''
      }else{
        sign = record.origin_account_currency_sign || ''
      }
    }

    return <span> {text !== '' && sign} {text !== '' ? formatted(text) : ''}</span>;
  }
);

export const RenderAccountAmount = React.memo(
  ({ text, record, type }: { text: string; record: DataType; type: 'credit' | 'debit' }) => {
    let sign = '';

    if (type === 'credit') {
      sign = record.destination_account_currency_sign || ''      
    } else if (type === 'debit') {
      sign = record.origin_account_currency_sign || ''      
    }

    return <span> {text !== '' && sign} {text !== '' ? formatted(text) : ''}</span>;
  }
);

export const RenderCheckout = React.memo((value:{text: string, record: DataType, isNumber?: boolean, type?: 'destination' | 'origin'}, )=>{
  let sign: string | undefined
  let txt = value.text

  if(value.isNumber){

    if(value.type && value.type == 'destination'){
      sign = value.record.destination_checkout_currency_sign
    }else if(value.type && value.type == 'origin'){
      sign = value.record.origin_checkout_currency_sign
    }
    txt = txt && Number(txt).toLocaleString("es-AR", {minimumFractionDigits: 2, maximumFractionDigits: 2})
  }
  return(<div>
    {txt && txt !== '' && sign && sign}
    {' '}
    {txt} 
    </div>)
}) 

export const RenderCustomer = React.memo((value: {text: any, record: DataType}) => {
    let colorTxt='#ADADAD'
    let customer = value.text

    
    if(value.record.full_name){
        customer = value.record.full_name
        colorTxt = '#FFC107'
    }

    if(value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'){
      colorTxt = 'rgb(114, 114, 114)'
    }
    if(value.record.operation_type_id == '5' ){
      customer = ''
    }
    
    return(
      <>
        {
          value.record.full_name
            ?
              <Tooltip
                title='Ventanilla '
              >
                <span
                  style={{
                      display: 'inline-block',
                      width: '100%', 
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color:colorTxt ,
                  }}
              >
                  {customer}  <QuestionCircleOutlined/>
              </span>
              </Tooltip>
            
            : <span>{customer}</span>
        }
      </> 
    )
})

export const RenderVentanilla = React.memo((value: {text: any, record: DataType}) => {
  let colorTxt='#ADADAD'
  let customer = value?.record?.full_name ? value.record.full_name : ''

  if(value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'){
      colorTxt = 'rgb(114, 114, 114)'
  }else if(value.record.full_name){
      customer = value.record.full_name
      colorTxt = '#FFC107'
  }
  
  return(
    <>
      {
        value.record.full_name
          &&
            <Tooltip
              title={value.record.full_name}
            >
              <span
                style={{
                    display: 'inline-block',
                    width: '100%', 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color:colorTxt ,
                }}
            >
                {customer}  
            </span>
            </Tooltip>                 
      }
    </> 
  )
})

