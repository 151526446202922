import { useEffect, useState } from "react";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { CashDeskProps } from "../../../Services/useGetCashDesk";
import { CheckboxProps, Col, Form, FormInstance, Row, Select } from "antd";
import { useCashDesk } from "../../../Services/useCashDesk";
import './styles.scss';
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext";
import { useGetBranch } from "../../../Services/useGetBranch";

export const FormItemsSell: React.FC<{
    form: FormInstance<any>
    selectedSellingCurrency:{
        id: string,
        sign: string,
        name: string
    } | undefined, 
    setSelectedSellingCurrency: Function,     
    rowSelect?: DataType
}> = ({
    form,
    selectedSellingCurrency, 
    setSelectedSellingCurrency,
    rowSelect
}) =>{
    const { Option } = Select;
    const {data: currencies} = useGetCurrency()  
    const {getCashCurrency} = useCashDesk()
    const {data: branches } = useGetBranch()

    const [checkedExtraction, setCheckedExtraction] = useState(true);
    const [listCahs, setListCash] = useState<CashDeskProps[]>()
    const [spanError, setSpanError] = useState<string>()
    const [branchSelected, setBranchSelected] = useState<number | undefined>()
    const [cashSelected, setCashSelected] = useState<number | null>(null);

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    useEffect(() => {
        setSpanError(undefined)
        let extraction_cash = form.getFieldValue('extraction_cash');
        initialValuesForm()       
        if(rowSelect){
            if(extraction_cash && extraction_cash.key){
                setCheckedExtraction(true)
            }else{
                setCheckedExtraction(false)
                setListCash(undefined)
            }
            if(rowSelect.origin_checkout_branch_id){
                setBranchSelected(+rowSelect.origin_checkout_branch_id)
            }
        }else{
            setCheckedExtraction(true)
            setListCash(undefined)
        }
      }, []);

    const initialValuesForm = async()=>{
        if(selectedSellingCurrency){            
            const data = await getCashCurrency(Number(selectedSellingCurrency.id)) 
            if(data){
                let is_not_bank = data.filter((x)=> x.type.id !== 2)
                setListCash(is_not_bank);
                if(data.length == 0){
                    setSpanError(`No existen cajas en ${selectedSellingCurrency.sign}. Cree una en Menú -> Administración -> Cajas`)
                }else{
                    if(spanError){
                        setSpanError(undefined)
                    }
                }
            } 
        }        
    }

    const onChangeExtraction: CheckboxProps['onChange'] =(e) => {
        setCheckedExtraction(e.target.checked);  
        form.setFieldValue('extraction_cash', undefined);                
    };   


    const onChangeCurrency = async(values: {key:string, label: string}, record: any)=>{   
        setSelectedSellingCurrency({
            id: values.key,
            sign: record?.sign ? record.sign : '',
            name: values.label 
        })
        
        form.setFieldValue('extraction_cash', undefined); 
        const data = await getCashCurrency(Number(values.key)) 
        if(data){
            let is_not_bank = data.filter((x)=> x.type.id !== 2)
            setListCash(is_not_bank)
            if(data.length == 0){
                setSpanError(`No existen cajas en ${ record?.sign ? record.sign : ''}. Cree una en Menú -> Administración -> Cajas`)
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        } 
    }

 
    return(
    <div className="section_sell">   
                   
        <Form.Item
            name='sell_currency'
            className="sell_and_buy_divide "
        > 
            <div className="section_sell_title">Moneda venta</div> 
            <Select                           
                labelInValue  
                value={selectedSellingCurrency ? {
                    value: selectedSellingCurrency?.name || '', //'Dolar
                    label: selectedSellingCurrency?.name || '', //Dolar
                    key: selectedSellingCurrency?.id || '', //2
                    sign: selectedSellingCurrency?.sign || '' //'USD
                } : undefined}
                loading={!currencies }
                placeholder={!currencies ? 'Cargando monedas...' : 'Moneda'}                       
                style={{minWidth: '105px'}}
                disabled={(rowSelect?.deposit_operation_approved == 'true' || rowSelect?.extraction_operation_approved == 'true' ) ? true : false}
                onChange={(values, record)=>{
                   onChangeCurrency(values, record)
                }}
            >
                {currencies && currencies && currencies.filter((x)=>x.id !==3).map((item) => (
                    <Option value={item.name} key={item.id} sign={item.sign}>
                        {item.name}
                    </Option>
                ))} 
            </Select>
        </Form.Item>
        <Form.Item>
            <p style={{margin:'0px', padding: '0p'}}>Extración en caja:</p>
        </Form.Item> 
            {
                checkedExtraction &&
                    <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Sucursal'
                                name='branch_cahs_extraction'
                                rules={mandatory}
                            >
                                <Select
                                    style={{minWidth: 120}}
                                    labelInValue  
                                    onChange={(e)=>{
                                        setBranchSelected(+e.key)
                                        setCashSelected(null);
                                        form.setFieldValue('extraction_cash', undefined)
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        item.id !== 0 &&
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Caja extracción'
                                name='extraction_cash' 
                                rules={mandatory}
                            >
                                <Select
                                    labelInValue
                                    style={{minWidth: 120}}
                                    placeholder= 'Caja'
                                    onChange={(e)=>setCashSelected(+e.key)}
                                    value={cashSelected ? { 
                                        key: cashSelected.toString(), 
                                        label: listCahs?.find((item)=>item.id == cashSelected)?.name, 
                                        value: listCahs?.find((item)=>item.id == cashSelected)?.name 
                                    } : null}  // Mostrar el valor seleccionado
                                    disabled={rowSelect?.deposit_operation_approved == 'true'  ? true : false}  
                                >
                                    {listCahs && listCahs
                                        .filter((item) => item.branch.id === branchSelected || item.branch.id == 0)
                                        .map((item) => (
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                   
                }            
        {spanError && <span style={{color: 'red'}}>{spanError}</span>} 
    </div>) 
}

